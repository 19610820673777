@media (min-width: 767px) {
  .report-qr-code-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
  .report-qr-code-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
        width: 5% !important;
        min-width: 5% !important;
        max-width: 5% !important;
  }
  .report-qr-code-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
  .report-qr-code-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
        width: 28% !important;
        min-width: 28% !important;
        max-width: 28% !important;
  }
  .report-qr-code-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
  .report-qr-code-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
        width: 13% !important;
        min-width: 13% !important;
        max-width: 13% !important;
  }
  .report-qr-code-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
  .report-qr-code-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
        width: 13% !important;
        min-width: 13% !important;
        max-width: 13% !important;
  }
  .report-qr-code-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
  .report-qr-code-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
        width: 11% !important;
        min-width: 11% !important;
        max-width: 11% !important;
  }
  .report-qr-code-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
  .report-qr-code-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
  }
  .report-qr-code-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7),
  .report-qr-code-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
  }
}
.report-qr-code-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {white-space: wrap;}

@media (max-width: 767px) {
  .report-qr-code-content .data-table-main .MuiDataGrid-root {min-width: 767px;}
  .report-qr-code-content div[data-field=id] {
    width: 5% !important;
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .report-qr-code-content div[data-field=ProjectName] {
    width: 18% !important;
    min-width: 18% !important;
    max-width: 18% !important;
  }
  .report-qr-code-content div[data-field=SerialCode] {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .report-qr-code-content div[data-field=QrTitle] {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .report-qr-code-content div[data-field=QRCode] {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .report-qr-code-content div[data-field=StartDate] {
    width: 16% !important;
    min-width: 16% !important;
    max-width: 16% !important;
  }
  .report-qr-code-content div[data-field=EndDate] {
    width: 16% !important;
    min-width: 16% !important;
    max-width: 16% !important;
  }
}

@media (max-width: 600px) {
  .report-qr-code-content .data-table-main .MuiDataGrid-root {min-width: 710px;}
}