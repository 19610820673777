.profile-input-box {
  width: 100px;
    height: 100px;
    border-radius: 50%;
  }
.profile-input-box .profile-upload-btn{
    display: none;
  }
  
  .profile-input-box .profile-cam-icon {
    font-size: 50;
    width: 100%;
    height: 100%;
  }
  .profile-image-input {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    cursor: pointer;
  }

  .admin-edit-profile-create-new-project-card{
    max-width: 550px;
    width: 100%;
}
.user-btn-flex .cancel-admin-btn{
  margin-right: 0px;
}
.user-btn-flex .save-admin-btn{
  margin-right: 10px;
}