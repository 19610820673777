.select-logo-switch-main{
      margin-left: 20px;
}
.input-box-auth .mandatory-asterisk{
color: red;
}
.edit-view-icons{
          height: 20px;
      width: 20px;
      margin-top: 25px;
      object-fit: contain;
  }
.qr-code-generation-section .display-flex{
/* padding-right: 0px; */
padding: 0px 0px 0px 8px;
margin-right: -20px;
}
.edit-main .edit-icon-btn{
      margin-left: -24px;
      margin-top: 2px
}