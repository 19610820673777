.new-project-box {
      height: 400px;
      width: 100%;
      max-width: 400px;
      object-fit: contain;
      border: 1px solid var(--main-border);
}

.new-project-lable-flex {
      display: flex;
      flex-wrap: wrap;
}
.new-project-lable-box .new-project-lable-text {
      color: var(--main-primary);
      /* font-family: 'Poppins-SemiBold'; */
      font-weight: 700;
      font-size: 15px;
      line-height: 23px;
      text-align: center;
}
.new-project-lable-box{
      border: 1px solid var(--main-border);
      padding: 6px 10px;
      margin-right: 20px;
      cursor: pointer;
      margin-bottom: 20px;
      width: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
}
.new-project-lable-box:last-child{
      margin-right: 0px;
}
.project-box-flex{
      display: flex;
}
.new-project-category-flex{
      flex-direction: column;
      margin-right: 20px;
}
.new-project-category-flex .new-project-lable-box{
      margin-right: 0px;
}