.admin-change-password-create-new-project-card{
    max-width: 550px;
    width: 100%;
}
/* .admin-change-password-flex{
    display: flex;
    align-items: center;
} */
.admin-change-password-flex-left{
max-width: 100%;
width: 100%;
flex: 0 0 100%;
}
.admin-change-password-flex-right{
flex: 1;
}