.publish-radio-flex {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
      padding: 0;
}

.publish-radio-content-main .publish-radio-title {
      color: var(--main-primary);
      /* font-family: 'Poppins-SemiBold'; */
      font-weight: 700;
      font-size: 15px;
      line-height: 23px;
      position: relative;
      width: max-content;
}

/* .publish-radio-content-main .publish-radio-title::after {
      border-bottom: 2px solid var(--main-primary);
      position: absolute;
      content: '';
      height: 2px;
      width: 100%;
      left: 0px;
      bottom: -5px;
} */

.publish-radio-content-main .publish-radio-para {
      color: #6B7280;
      font-size: 13px;
      line-height: 23px;
      /* font-family: 'Poppins-Regular'; */
      margin: 15px 0px 0px;
}
.publish-radio-content-main .para-select {
      color:var(--main-dark-blue);
      font-size: 13px;
      line-height: 23px;
      /* font-family: 'Poppins-Regular'; */
      margin: 15px 0px 0px;
}

.publish-radio-box {
      width: calc(100% / 3 - 0px);
      padding: 0px 10px 20px;
}

.publish-radio-box .publish-radio-content-main:hover {
      cursor: pointer;
}

.publish-radio-box:last-child {
      margin-right: 0px;
}

.publish-radio-content-main {
      padding: 15px;
      border: 1px solid var(--main-border);
      border-radius: 6px;
}

.common-modal .publish-modal-inner {
      min-width: 800px;
}