@font-face {
  font-family: "poppins-regular";
  src: url("../fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "poppins-light";
  src: url("../fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "poppins-medium";
  src: url("../fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "poppins-bold";
  src: url("../fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "poppins-semibold";
  src: url("../fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Roberto Sans";
  src: url("../fonts/RobertoSans-Thin.woff2") format("woff2"),
    url("../fonts/RobertoSans-Thin.woff") format("woff"),
    url("../fonts/RobertoSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roberto Sans";
  src: url("../fonts/RobertoSans-Light.woff2") format("woff2"),
    url("../fonts/RobertoSans-Light.woff") format("woff"),
    url("../fonts/RobertoSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roberto Sans";
  src: url("../fonts/RobertoSans-Medium.woff2") format("woff2"),
    url("../fonts/RobertoSans-Medium.woff") format("woff"),
    url("../fonts/RobertoSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roberto Sans";
  src: url("../fonts/RobertoSans-Regular.woff2") format("woff2"),
    url("../fonts/RobertoSans-Regular.woff") format("woff"),
    url("../fonts/RobertoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roberto Sans";
  src: url("../fonts/RobertoSans-Bold.woff2") format("woff2"),
    url("../fonts/RobertoSans-Bold.woff") format("woff"),
    url("../fonts/RobertoSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roberto Sans";
  src: url("../fonts/RobertoSans-Black.woff2") format("woff2"),
    url("../fonts/RobertoSans-Black.woff") format("woff"),
    url("../fonts/RobertoSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roberto Sans";
}

:root {
  --main-white: #ffffff;
  --main-primary: #2b324c;
  --main-dark-blue: #3e53a0;
  --main-transparent: #5a77df;
  --main-secondary: #323954;
  --main-off-white: #ccd4de;
  --main-mid-white: #eceef0;
  --main-red: #ff0000;
  --main-bg: #fafafa;
  --main-border: #e0e0e0;
}

.error-text {
  /* font-family: "Poppins-Regular"; */
  font-size: 15px;
  line-height: 17px;
  margin: 5px 0px 0px 0px;
  color: var(--main-red);
}

.primary-btn-content .comman-primary-btn,
.primary-btn-content .comman-primary-btn:hover,
.primary-btn-content .comman-primary-btn:active {
  /* background: #2b234c; */
  background: var(--main-primary);
  color: var(--main-white);
  text-transform: capitalize;
  /* font-family: "poppins-regular"; */
  font-size: 16px;
  line-height: 18px;
  padding: 12.5px 25px;
}

.primary-btn-content .comman-primary-btn:hover {
  opacity: 0.85;
}

.border-btn-main .border-btn,
.border-btn-main .border-btn:active {
  background: transparent;
  color: var(--main-primary);
  text-transform: capitalize;
  /* font-family: "poppins-regular"; */
  font-size: 16px;
  line-height: 18px;
  border: 1px solid var(--main-border);
  padding: 12.5px 25px;
  transition: 0.5s;
}

.border-btn-main .border-btn:hover {
  background: var(--main-transparent);
  color: var(--main-white);
}

/* start common table design */

.page-table-main .table-container {
  padding-bottom: 0;
  background-color: transparent;
  box-shadow: none;
  overflow: auto;
  max-width: calc(100vw - 0px);
}

.page-table-main .table-th {
  font-size: 15px;
  line-height: 18px;
  color: var(--main-white);
  font-weight: 700;
  /* font-family: "Poppins-Semibold"; */
  background: var(--main-dark-blue);
  padding: 15px 15px;
  white-space: nowrap;
}

.page-table-main .table-td {
  font-size: 12px;
  line-height: 18px;
  color: var(--main-secondary);
  display: table-cell;
  /* font-family: "Poppins-Regular"; */
  border-bottom: 1px solid var(--main-border);
  padding: 9px 15px;
}

/* .page-table-main .table .table-th:nth-child(1),
.page-table-main .table .table-td:nth-child(1) {
  width: 5%;
}

.page-table-main .table .table-th:nth-child(2),
.page-table-main .table .table-td:nth-child(2) {
  width: 5%;
}

.page-table-main .table .table-th:nth-child(3),
.page-table-main .table .table-td:nth-child(3) {
  width: 5%;
}

.page-table-main .table .table-th:nth-child(4),
.page-table-main .table .table-td:nth-child(4) {
  width: 5%;
}

.page-table-main .table .table-th:nth-child(5),
.page-table-main .table .table-td:nth-child(5) {
  width: 5%;
} */

.page-table-main .table .no-data-found-table-td:nth-child(1) {
  width: 5%;
  text-align: center;
}

/* .page-table-main .table .table-th:first-child,
.page-table-main .table .table-td:first-child {
    border-radius: 6px 0 0 6px;
}

.page-table-main .table .table-th:last-child,
.page-table-main .table .table-td:last-child {
    border-radius: 0 6px 6px 0;
} */

.table-section {
  background-color: #f0f3f8;
  border-radius: 6px;
  padding: 15px;
}

.page-table-main .table {
  border-collapse: initial;
  border-radius: 0;
  min-width: 1100px;
}

.table-view-icons {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.table-icon-flex .table-icon-btn {
  min-width: 30px;
  margin-right: 2px;
  padding: 0px;
}

.table-icon-flex .table-icon-btn:last-child {
  margin-right: 0px;
}

.table-thumbnail-icons {
  height: 35px;
  width: 35px;
  object-fit: contain;
  border-radius: 5px;
}

.table-td .top-creators-icons {
  filter: none;
}

/* width */
.page-table-main .table-container::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

.table-icon-flex {
  display: flex;
  align-items: center;
}

/* Track */
.page-table-main .table-container::-webkit-scrollbar-track {
  background: #d1d5db;
  border-radius: 5px;
}

/* Handle */
.page-table-main .table-container::-webkit-scrollbar-thumb {
  background: #8888885c;
}

/* Handle on hover */
.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* end common table design */

/* start common title design */
.user-page-title-box .user-page-title {
  color: var(--main-primary);
  font-size: 21px;
  line-height: 34px;
  font-weight: 700;
  /* font-family: "Poppins-SemiBold"; */
  margin-bottom: 20px;
}

.user-page-sub-title-box .user-page-sub-title {
  color: var(--main-dark-blue);
  font-size: 17px;
  line-height: 25px;
  font-weight: 700;
  /* font-family: "Poppins-SemiBold"; */
}

.user-list-flex .user-page-title-box .user-page-title {
  margin-bottom: 0px;
}

.user-list-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.user-list-flex .user-list-page-title {
  margin-bottom: 0px;
}

.user-search-flex .user-page-sub-title {
  margin-bottom: 0px;
}

.user-page-sub-title-box {
  margin-bottom: 20px;
}

/* end common title design */

/* start tab design  */

.user-tabs-main .user-tab {
  color: var(--main-secondary);
  font-size: 15px;
  line-height: 23px;
  /* font-family: "Poppins-Regular"; */
  text-transform: capitalize;
  padding: 0px 15px 0px 15px;
}

.user-tabs-main .user-tab:last-child {
  margin-right: 0px;
}

.user-tabs-main .user-tab.Mui-selected {
  color: var(--main-white);
  font-weight: 700;
  /* font-family: "Poppins-SemiBold"; */
  background: var(--main-primary);
  border-radius: 5px 5px 0px 0px;
}

.user-tabs-main .MuiTabs-indicator {
  background-color: var(--main-primary);
  height: 2px;
  display: none;
}

.user-tabpanel > .MuiBox-root {
  padding: 20px 0px;
}

/* end tab design  */

/* start pagination css */

.pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.pagination-main .MuiPagination-ul {
  margin-right: 20px;
}

.pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: #5a5959;
  /* font-family: "Poppins-Regular"; */
  margin: 0 4px;
}

.pagination-main .MuiPaginationItem-root.Mui-selected:hover {
  background-color: var(--main-primary);
  color: var(--main-white);
}

.pagination-main .MuiPaginationItem-root.Mui-selected {
  background-color: var(--main-primary);
  color: var(--main-white);
}

/* end pagination css */

/* / start dropdown css / */

.dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.dropdown-select .MuiSelect-select {
  color: var(--main-secondary);
  font-size: 12px;
  line-height: 20px;
  /* font-family: "Poppins-Regular"; */
  display: flex;
  padding: 11px 15px;
  border-radius: 4px;
}

.grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.dropdown-box .MuiMenu-list .drop-menuitem {
  color: var(--main-secondary);
  font-size: 12px;
  line-height: 20px;
  /* font-family: "Poppins-Regular"; */
}

/* / end dropdown css / */

.input-box-auth .form-control-textarea {
  width: 100%;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  padding: 10px;
  min-height: 100px;
  resize: none;
  color: var(--main-primary);
  font-size: 14px;
  line-height: 17px;
  /* font-family: "poppins-regular"; */
  background: transparent;
}

.user-btn-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0px 0px;
}

.common-card {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px;
  background-color: var(--main-white);
  padding: 20px;
  border-radius: 6px;
}

.btns-icons {
  height: 18px;
  width: 18px;
  object-fit: contain;
  margin-right: 10px;
}

.common-modal .common-modal-inner {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px;
  background-color: var(--main-white);
  border-radius: 10px;
  max-height: calc(100% - 100px);
  overflow: auto;
  padding: 20px;
}

.modal-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.common-modal-inner .modal-title-text {
  color: var(--main-dark-blue);
  font-size: 17px;
  line-height: 25px;
  font-weight: 700;
  /* font-family: "Poppins-SemiBold"; */
  margin-bottom: 30px;
}

.radio-main .radiogroup .MuiButtonBase-root.Mui-checked {
  color: #114a65;
}

.radio-main .MuiFormControlLabel-label {
  color: #6b7280;
  font-size: 14px;
  line-height: 21px;
  /* font-family: "Poppins-Regular"; */
}

.modal-close-icon {
  height: 23px;
  width: 22px;
  object-fit: contain;
  position: absolute;
  right: 23px;
  top: 21px;
  cursor: pointer;
}

/* width */
.common-modal-inner::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.common-modal-inner::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

/* Handle */
.common-modal-inner::-webkit-scrollbar-thumb {
  background: var(--main-primary);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

/* Handle on hover */
.common-modal-inner::-webkit-scrollbar-thumb:hover {
  background: var(--main-primary);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.table-icon-btn .table-analytic-icons {
  height: 30px;
  width: 35px;
  object-fit: contain;
}

.table-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

/* dark theme start */

.dark-theme .user-search-box .form-control input {
  color: #ffffff;
}

.dark-theme .user-search-box .form-control input {
  color: #ffffffe0;
}

:root .dark-theme {
  --main-white: #ffffffe0;
  --main-primary: #a6bbd3;
  --main-dark-blue: #050d29;
  --main-transparent: #5a77df;
  --main-secondary: #6b799e;
  --main-off-white: #ccd4de;
  --main-mid-white: #eceef0;
  --main-red: #ff0000;
  --main-bg: #050d29;
  --main-border: #e0e0e0;
  --stat-box: #3e53a0;
  --stat-box-border: #ffffff42;
  --f-white: #ffffff;
  --from-border: #ffffffe0;
}

.dark-theme .common-card {
  background: #3e53a0;
}

.dark-theme .dark-to-light-img {
  filter: grayscale(1) invert(1);
  opacity: 0.75;
}

.dark-theme td img {
  filter: grayscale(1) invert(1);
  opacity: 0.75;
}

.dark-theme .page-table-main .table-th,
.dark-theme .page-table-main .table-td {
  border-bottom: 1px solid var(--stat-box-border);
}

.dark-theme .user-page-sub-title-box .user-page-sub-title {
  color: var(--main-white);
}

.dark-theme .user-dashboard-inner-box .user-dash-text {
  color: var(--main-white);
}

.dark-theme .user-dashboard-inner-box .user-dash-price {
  color: var(--main-white);
}

.dark-theme .input-box-auth .label-input-title {
  color: var(--main-white);
}

.dark-theme .user-page-title-box .user-page-title {
  color: var(--main-white);
}

.dark-theme .file-upload-btn-main .choose-img-text {
  color: var(--main-white);
}

.dark-theme .add-tags-box .add-tags-text {
  color: var(--main-white);
}

.dark-theme .radio-main .MuiFormControlLabel-label {
  color: var(--main-white);
}

.dark-theme .input-box-auth .form-control input::placeholder,
.dark-theme .input-box-auth .form-control-textarea::placeholder {
  color: var(--main-white);
}

.dark-theme .input-box-auth .form-control input {
  color: rgba(255, 255, 255, 0.75);
}

.dark-theme .help-info-flex .help-info-lable {
  color: var(--main-white);
}

.dark-theme .tags-addd-plus-main {
  background-color: var(--stat-box);
}

.dark-theme fieldset {
  border-color: var(--stat-box-border);
}

.dark-theme .dropdown-box svg,
.dark-theme button svg {
  fill: #ffffff;
  opacity: 0.75;
}

.dark-theme .MuiSelect-select {
  color: #ffffff;
}

.dark-theme svg text,
.dark-theme svg .apexcharts-legend-text {
  fill: #ffffff;
  color: #ffffff !important;
}

.dark-theme .primary-btn-content .comman-primary-btn,
.dark-theme .primary-btn-content .comman-primary-btn:hover,
.dark-theme .primary-btn-content .comman-primary-btn:active {
  background: var(--main-transparent);
}

.dark-theme .print-icon-box {
  border-color: var(--stat-box-border);
}

.dark-theme .pagination-main p {
  color: #ffffff;
}

.dark-theme .page-table-main .table-td {
  color: var(--main-white);
}

.dark-theme .user-tabs-main .user-tab.Mui-selected {
  background: var(--main-transparent);
}

.dark-theme .input-box-auth .form-control input,
.dark-theme .input-box-auth .form-control-textarea {
  border-color: var(--stat-box-border);
  color: rgba(255, 255, 255, 0.75);
}

.dark-theme .add-tags-box,
.dark-theme .new-project-lable-box,
.dark-theme .new-project-box {
  border-color: var(--from-border);
}

.dark-theme .print-icons {
  filter: grayscale(1) invert(1);
  opacity: 0.75;
}

.dark-theme .pagination-main svg {
  fill: #ffffff;
}

.dark-theme .pagination-main .MuiTablePagination-actions .Mui-disabled {
  opacity: 0.5;
}

.dark-theme .user-details-flex .user-details-data {
  color: var(--main-mid-white);
}

.dark-theme .page-table-main .table-container::-webkit-scrollbar {
  width: 5px;
}

.dark-theme .page-table-main .table-container::-webkit-scrollbar-track {
  background-color: var(--main-bg);
  border-radius: 4px;
}

.dark-theme .page-table-main .table-container::-webkit-scrollbar-thumb {
  background-color: var(--main-transparent);
  border-radius: 4px;
}

.dark-theme .date-picker-main .form-control label {
  color: #ffffff;
}

.dark-theme td .css-ynrbsr {
  border-color: var(--main-transparent);
}

.dark-theme td .css-1oyuqr7 {
  border-color: var(--main-transparent);
}

.dark-theme td .css-1oyuqr7::before {
  filter: grayscale(1) invert(1);
  opacity: 1;
}

.dark-theme img.trash-icon {
  filter: grayscale(1) invert(1);
  opacity: 1;
}

.dark-theme .cust-acc-main .cust-acc-summary {
  border-color: var(--stat-box-border);
}

.dark-theme .cust-acc-main .cust-accordian .cust-acc-summary.Mui-expanded {
  border-color: var(--stat-box-border);
}

.dark-theme .cust-acc-main svg {
  filter: grayscale(1) invert(1);
  opacity: 1;
}

.dark-theme .cust-acc-main .cust-acc-details {
  border: var(--main-white);
  background-color: transparent;
}

.dark-theme .acc-details-main .acc-details-para {
  color: #ffffff;
}

.dark-theme .help-info-flex .help-info-data {
  color: #ffffff;
}

.dark-theme .pagination-main .MuiPaginationItem-root {
  color: #ffffff;
  border-color: var(--stat-box-border);
}

.dark-theme .pagination-main .MuiPaginationItem-root.Mui-selected {
  background: var(--stat-box-border);
}

.dark-theme .light-gray {
  background: var(--main-dark-blue);
}

.dark-theme .remember-me label {
  color: var(--main-primary);
}

.dark-theme .remember-me svg {
  fill: var(--main-primary);
}

.dark-theme .primary-btn-content .login-btn:disabled {
  color: #ffffff;
  opacity: 0.65;
}

.dark-theme .published-icoon-box img {
  filter: grayscale(1) invert(1);
  opacity: 1;
}

.dark-theme .password-require-ensure,
.dark-theme .password-require-title,
.dark-theme .password-ul li {
  color: #ffffff;
}

.dark-theme .user-header-drop-main .dark-to-light-img {
  filter: inherit;
}

.dark-theme .radio-main svg {
  fill: rgba(255, 255, 255, 0.3);
}

.dark-theme .ck-editor {
  background: red;
}

.dark-theme .ck-editor svg.ck-button__icon {
  fill: #ffffff;
  color: #ffffff;
}

.dark-theme .ck.ck-editor__top,
.dark-theme .ck.ck-sticky-panel,
.dark-theme .ck.ck-sticky-panel__content,
.dark-theme .ck.ck-toolbar.ck-toolbar_grouping {
  background: var(--main-dark-blue);
}

.dark-theme .ck.ck-content.ck-editor__editable {
  background: var(--stat-box);
  border-color: var(--stat-box-border);
}

.dark-theme .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.dark-theme .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-color: var(--stat-box-border);
}

.dark-theme .ck.ck-dropdown span,
.dark-theme .ck.ck-dropdown span + svg {
  color: #ffffff;
}

.dark-theme .ck.ck-dropdown button.ck.ck-button:hover {
  background: none;
}

.dark-theme .ck.ck-editor__editable * {
  color: #ffffff;
}

.dark-theme .ck.ck-list,
.dark-theme .ck.ck-dropdown__panel {
  background: var(--stat-box);
  border-color: var(--stat-box-border);
}

.dark-theme .common-modal .common-modal-inner {
  background: var(--stat-box);
  border-color: var(--stat-box-border);
}

.dark-theme .common-modal .common-modal-inner .modal-title-text {
  color: #ffffff;
}

.dark-theme .common-modal img.modal-close-icon,
.dark-theme img.copy-icon {
  filter: grayscale(1) invert(1);
  opacity: 0.85;
}

.dark-theme .border-btn-main .border-btn {
  border-color: var(--from-border);
}

.dark-theme .border-btn-main .border-btn:hover {
  background: #ffffff;
  color: var(--stat-box);
}

.dark-theme .publish-radio-content-main {
  border-color: var(--from-border);
}

.dark-theme .border-btn-main .border-btn {
  border-color: var(--stat-box-border);
}

.dark-theme .border-btn-main .border-btn:hover {
  background: #ffffff;
  color: var(--stat-box);
}

.dark-theme .publish-radio-content-main {
  border-color: var(--stat-box-border);
}

.dark-theme .publish-radio-content-main .publish-radio-para,
.dark-theme .published-succses-main .published-para-text {
  color: #ffffff;
}

.dark-theme .active-plan {
  border-color: #ffffff;
}

.dark-theme .published-icoon-box {
  border-color: var(--stat-box-border);
}

.dark-theme .MuiDataGrid-cellContent {
  color: #a3b9f3;
}

.dark-theme .data-table-main .MuiDataGrid-withBorderColor {
  border: 0;
}

.dark-theme .data-table-main .MuiDataGrid-withBorderColor {
  border-bottom: 1px solid var(--stat-box-border);
}

.dark-theme .data-table-main .MuiDataGrid-footerContainer * {
  color: #ffffff;
}

.dark-theme.drop-header-menu .MuiPopover-paper {
  background: var(--stat-box);
  border-color: var(--stat-box-border);
}

.dark-theme.drop-header-menu .MuiPopover-paper li {
  border-color: var(--stat-box-border);
  color: #ffffff;
}

.dark-theme.drop-header-menu .MuiPopover-paper li img {
  filter: grayscale(1) invert(1);
  opacity: 0.85;
}

.dark-theme .MuiPaper-elevation {
  background: var(--stat-box);
}

.dark-theme .MuiPaper-elevation li {
  color: #ffffff;
}

.dark-theme .MuiPaper-elevation hr {
  border-color: var(--from-border);
}

.dark-theme .MuiPaper-elevation svg {
  filter: grayscale(1) invert(1);
  opacity: 1;
}

.dark-theme .MuiPickersPopper-paper .MuiPickersCalendarHeader-label {
  color: #ffffff;
}

.dark-theme .MuiPickersPopper-paper .MuiDayCalendar-header span {
  color: #ffffff;
}

.dark-theme .MuiPickersPopper-paper .MuiDayCalendar-weekContainer button {
  color: #98acdf;
}

.dark-theme
  .MuiPickersPopper-paper
  .MuiDayCalendar-weekContainer
  button.MuiPickersDay-today {
  color: #ffffff;
  border-color: #ffffff;
}

.dark-theme .MuiPickersPopper-paper button.MuiPickersYear-yearButton {
  color: #7283af;
}

.dark-theme
  .MuiPickersPopper-paper
  button.MuiPickersYear-yearButton.Mui-selected {
  color: #050d29;
}

.dark-theme .MuiPickersPopper-paper svg {
  fill: #ffffff;
  filter: none;
}

.dark-theme .tabs-main-box > div > div {
  border-color: var(--stat-box-border);
}

.dark-theme .view-flex-col {
  border-color: var(--stat-box-border);
}

.dark-theme .view-flex-col p {
  color: #ffffff;
}

.dark-theme .media-btns-main .media-btns.active {
  background: var(--stat-box);
}

.dark-theme .media-btns-main {
  border-bottom: 1px solid var(--stat-box-border);
}

.dark-theme
  .MuiSwitch-root
  .MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #213274;
}

.dark-theme .MuiSwitch-root .MuiSwitch-switchBase + .MuiSwitch-track {
  background-color: rgba(255, 255, 255, 0.3);
}

[type="date"] {
  color: var(--stat-box);
}

.dark-theme .cust-acc-main .cust-accordian {
  background: var(--main-dark-blue);
}

.dark-theme .search-grey-img {
  filter: brightness(1.5);
}

.dark-theme .tabpanel-main ::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4) !important;
}

.dark-theme .tabpanel-main ::placeholder {
  color: rgba(255, 255, 255, 0.4) !important;
}

.dark-theme .input-box-auth .shap-text {
  color: rgba(255, 255, 255, 0.4);
}

* {
  outline: none;
}

.dark-theme .user-tabs-main .user-tab {
  color: rgba(255, 255, 255, 0.65);
}

.dark-theme .user-tabs-main .user-tab.Mui-selected {
  color: rgba(255, 255, 255, 1);
}

textarea::placeholder {
  opacity: 0.4;
}

.MuiRadio-root.Mui-checked svg {
  fill: #3e54a0;
}

.dark-theme .MuiRadio-root.Mui-checked svg {
  fill: #ffffff;
}

.dark-theme .user-details-flex .user-details-lable {
  color: #95acff;
}

.ck.ck-button:active,
.ck.ck-button:focus,
a.ck.ck-button:active,
a.ck.ck-button:focus {
  border: 0;
  box-shadow: none;
}

.dark-theme input[autocomplete*="one-time-code"] {
  color: #ffffff;
}

.dark-theme .condition-content p {
  color: var(--main-secondary);
}

/* dark theme end */

.page-table-main .table tr:last-child td {
  border-bottom: 1px solid var(--main-border);
}

.dark-theme .page-table-main .table tr:last-child td {
  border-bottom: 1px solid var(--stat-box-border);
}

.page-table-main .table {
  min-width: 1000px;
}

.apexcharts-menu-item {
  white-space: nowrap;
}

.user-search-flex .user-page-sub-title-box {
  margin-bottom: 0;
}

button.comman-primary-btn.send-otp-btn {
  color: #ffffff !important;
}

.dropdown-box #mui-component-select-country {
  padding-left: 15px;
}

/* .data-table-main .MuiDataGrid-columnHeader, .data-table-main .MuiDataGrid-cell {width: 20% !important; max-width: 20% !important; min-width: 20% !important;} */
/* .css-kzyyzb-MuiDataGrid-root .MuiDataGrid-row {width: 100% !important;} */
/* .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone {width: 100%;} */
/* .data-table-main .MuiDataGrid-columnHeadersInner {width: 100%;} */
/* .MuiDataGrid-columnHeadersInner > div {
  width: 100%;
} */

/* .MuiDataGrid-virtualScrollerRenderZone {
  width: 100%;
} */
/* .data-table-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row {
  width: 100%;
} */

.data-table-main {
  overflow: auto;
}

.data-table-main .MuiDataGrid-root {
  min-width: 979px;
}

.data-table-main .MuiDataGrid-columnHeadersInner {
  font-size: 15px;
  line-height: 18px;
  color: var(--main-white);
  /* font-family: 'Poppins-Semibold'; */
  font-weight: 700;
  background: var(--main-dark-blue);
  white-space: nowrap;
  width: 100%;
}

.data-table-main .MuiDataGrid-columnHeadersInner [role="row"],
.data-table-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row,
.data-table-main .MuiDataGrid-virtualScrollerRenderZone {
  width: 100%;
}

.data-table-main .MuiDataGrid-virtualScrollerContent {
  height: auto !important;
}

.data-table-main .MuiDataGrid-virtualScrollerRenderZone {
  position: relative;
}

.data-table-main .MuiDataGrid-iconButtonContainer svg {
  color: white;
}

.data-table-main .MuiDataGrid-columnSeparator svg {
  display: none;
}

.data-table-main .MuiDataGrid-menuIconButton svg {
  color: white;
  margin-right: 10px;
}

.primary-btn-content + .border-btn-main button {
  margin-left: 15px;
}

textarea {
  font-family: Roberto Sans, sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: var(--main-primary);
}

.MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
  text-transform: capitalize;
  visibility: hidden;
}

.MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader svg {
  margin-right: 0;
  fill: var(--main-white);
}

.user-sidebar-logo.mobile-show {
  display: none;
}

.menu-report-section.common-card.user-management-wrape
  .data-table-main
  .MuiDataGrid-root {
  min-width: 800px;
}

.user-submenu-link {
  width: 100%;
}

.MuiDataGrid-row,
.MuiDataGrid-cell {
  max-height: inherit !important;
}
.MuiDataGrid-cellContent {
  overflow: inherit !important;
  text-overflow: inherit !important;
  word-wrap: break-word !important;
  width: 100%;
}

.MuiDataGrid-cell {
  overflow: inherit !important;
  white-space: wrap !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  word-wrap: break-word !important;
}

div.MuiTooltip-popper {
  z-index: 5 !important;
}

.published-succses-main .input-box-auth .form-control input {
  padding: 8.95px 40px 8.95px 10px;
}

.form-group .switch-main .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(14px);
}

.userlist-btn-flex .mulitiple-datepicker {
  display: flex;
  align-items: center;
}
.userlist-btn-flex .mulitiple-datepicker .text {
  color: #000000;
  margin-left: 10px;
}

.dark-theme .userlist-btn-flex .mulitiple-datepicker .text {
  color: #ffffff;
  margin-left: 10px;
}

.MuiDataGrid-columnHeader
  .MuiDataGrid-columnHeaderDraggableContainer
  .MuiDataGrid-columnHeaderTitleContainer {
  white-space: pre-wrap;
}
.MuiDataGrid-columnHeader
  .MuiDataGrid-columnHeaderDraggableContainer
  .MuiDataGrid-columnHeaderTitle {
  text-overflow: inherit;
  overflow: inherit;
  white-space: pre-wrap;
}
.MuiDataGrid-root
  .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor:first-child {
  border-left: 0;
}
.MuiDataGrid-root .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor {
  border: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.MuiDataGrid-root .MuiDataGrid-cell.MuiDataGrid-withBorderColor:first-child {
  border-left: 0;
}
.MuiDataGrid-root .MuiDataGrid-cell.MuiDataGrid-withBorderColor {
  border: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.dashboard-content p.MuiTablePagination-selectLabel,
.dashboard-content div.MuiTablePagination-input {
  display: flex;
}

/* .dark-theme .ck.ck-button:not(.ck-disabled):active, 
.dark-theme a.ck.ck-button:not(.ck-disabled):active, 
.dark-theme .ck.ck-button:not(.ck-disabled):active, 
.dark-theme a.ck.ck-button:not(.ck-disabled):hover {
  background: none !important;
}

.ck.ck-dropdown .ck-button.ck-dropdown__button.ck-off:active:focus, 
.ck.ck-dropdown .ck-button.ck-dropdown__button.ck-on:active:focus {
  box-shadow: none !important;
} */
.apexcharts-menu-icon{
  margin-left: 3px;
}
.apexcharts-zoom-icon,
.apexcharts-pan-icon {
  display: none !important;
}
.apexcharts-menu-icon,
.apexcharts-reset-icon,
.apexcharts-zoom-icon {
  transform: scale(0.85);
  /* display: none !important; */
}
.apexcharts-canvas .apexcharts-element-hidden,
.apexcharts-datalabel.apexcharts-element-hidden,
.apexcharts-hide .apexcharts-series-points {
  /* opacity: 1 !important; */
  display: none;
}
.apexcharts-menu-icon,
.apexcharts-pan-icon,
.apexcharts-reset-icon,
.apexcharts-selection-icon,
.apexcharts-toolbar-custom-icon,
.apexcharts-zoom-icon,
.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 24px;
  color: #6e8192;
  text-align: center;
}
.apexcharts-canvas .apexcharts-reset-zoom-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-selection-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg {
  fill: #008ffb;
}
.apexcharts-pan-icon {
  transform: scale(0.62);
  position: relative;
  left: 1px;
  top: 0;
}
.apexcharts-pan-icon svg {
  fill: #fff;
  stroke: #6e8192;
  stroke-width: 2;
}
.apexcharts-reset-icon {
  margin-left: 0 !important;
}
.apexcharts-zoomout-icon {
  margin-right: 1px !important;
}
.MuiDataGrid-cellContent[title*=] {
  opacity: 0.5
  }