.table-check .MuiCheckbox-root {
  padding: 0px;
}

.user-search-box-inner {
  margin-right: 10px;
}

@media (min-width: 767px) {
  .user-qr-code-list-published-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
  .user-qr-code-list-published-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
    width: 5% !important;
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .user-qr-code-list-published-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
  .user-qr-code-list-published-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
    width: 17% !important;
    min-width: 17% !important;
    max-width: 17% !important;
  }
  .user-qr-code-list-published-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
  .user-qr-code-list-published-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
    width: 18% !important;
    min-width: 18% !important;
    max-width: 18% !important;
  }
  .user-qr-code-list-published-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
  .user-qr-code-list-published-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
    width: 18% !important;
    min-width: 18% !important;
    max-width: 18% !important;
  }
  .user-qr-code-list-published-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
  .user-qr-code-list-published-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-qr-code-list-published-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
  .user-qr-code-list-published-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-qr-code-list-published-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7),
  .user-qr-code-list-published-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
    width: 12% !important;
    min-width: 12% !important;
    max-width: 12% !important;
  }



  .user-qr-code-list-non-published .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
  .user-qr-code-list-non-published .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
    width: 7% !important;
    min-width: 7% !important;
    max-width: 7% !important;
  }
  .user-qr-code-list-non-published .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
  .user-qr-code-list-non-published .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
    width: 5% !important;
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .user-qr-code-list-non-published .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
  .user-qr-code-list-non-published .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
    width: 23% !important;
    min-width: 23% !important;
    max-width: 23% !important;
  }
  .user-qr-code-list-non-published .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
  .user-qr-code-list-non-published .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
    width: 17% !important;
    min-width: 17% !important;
    max-width: 17% !important;
  }
  .user-qr-code-list-non-published .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
  .user-qr-code-list-non-published .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
    width: 20% !important;
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .user-qr-code-list-non-published .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
  .user-qr-code-list-non-published .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
    width: 18% !important;
    min-width: 18% !important;
    max-width: 18% !important;
  }

  .user-qr-code-list-non-published .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7),
  .user-qr-code-list-non-published .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
}

@media (max-width: 767px) {
  .user-qr-code-list-published-wrape .data-table-main .MuiDataGrid-root {min-width: 767px;}
  .user-qr-code-list-published-wrape div[data-field=SrNo] {
    width: 5% !important;
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .user-qr-code-list-published-wrape div[data-field=SerialCode] {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-qr-code-list-published-wrape div[data-field=QrTitle] {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-qr-code-list-published-wrape div[data-field=ProjectThumbnail] {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-qr-code-list-published-wrape div[data-field=StartDate] {
    width: 18% !important;
    min-width: 18% !important;
    max-width: 18% !important;
  }
  .user-qr-code-list-published-wrape div[data-field=EndDate] {
    width: 17% !important;
    min-width: 17% !important;
    max-width: 17% !important;
  }
  .user-qr-code-list-published-wrape div[data-field=action] {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }


  .user-qr-code-list-non-published .data-table-main .MuiDataGrid-root {min-width: 767px;}
  .user-qr-code-list-non-published div[data-field=__check__] {
    width: 8% !important;
    min-width: 8% !important;
    max-width: 8% !important;
  }
  .user-qr-code-list-non-published div[data-field=id] {
    width: 5% !important;
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .user-qr-code-list-non-published div[data-field=ProjectTitle] {
    width: 20% !important;
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .user-qr-code-list-non-published div[data-field=Tags] {
    width: 13% !important;
    min-width: 13% !important;
    max-width: 13% !important;
  }
  .user-qr-code-list-non-published div[data-field=CreatedDate] {
    width: 21% !important;
    min-width: 21% !important;
    max-width: 21% !important;
  }
  .user-qr-code-list-non-published div[data-field=PublishedDate] {
    width: 21% !important;
    min-width: 21% !important;
    max-width: 21% !important;
  }
  .user-qr-code-list-non-published div[data-field=action] {
    width: 12% !important;
    min-width: 12% !important;
    max-width: 12% !important;
  }
}

@media (max-width: 600px) {
  .user-qr-code-list-published-wrape .data-table-main .MuiDataGrid-root {min-width: 720px;}
  .user-qr-code-list-published-wrape div[data-field=SrNo] {
    width: 5% !important;
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .user-qr-code-list-published-wrape div[data-field=SerialCode] {
    width: 14% !important;
    min-width: 14% !important;
    max-width: 14% !important;
  }
  .user-qr-code-list-published-wrape div[data-field=QrTitle] {
    width: 12% !important;
    min-width: 12% !important;
    max-width: 12% !important;
  }
  .user-qr-code-list-published-wrape div[data-field=ProjectThumbnail] {
    width: 17% !important;
    min-width: 17% !important;
    max-width: 17% !important;
  }
  .user-qr-code-list-published-wrape div[data-field=StartDate] {
    width: 17% !important;
    min-width: 17% !important;
    max-width: 17% !important;
  }
  .user-qr-code-list-published-wrape div[data-field=EndDate] {
    width: 16% !important;
    min-width: 16% !important;
    max-width: 16% !important;
  }
  .user-qr-code-list-published-wrape div[data-field=action] {
    width: 19% !important;
    min-width: 19% !important;
    max-width: 19% !important;
  }


  .user-qr-code-list-non-published .data-table-main .MuiDataGrid-root {min-width: 650px;}
}

.user-qr-code-list-published-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {white-space: wrap;}
.user-qr-code-list-non-published .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {white-space: wrap;}