.dark-gray {
    background: var(--main-primary);
    height: 100vh;
    position: fixed;
    width: 35%;
    top: 0;
    bottom: 0;
    left: 0;
}
.dark-theme .login-user-screen { background: var(--main-dark-blue); height: 100vh;}
.dark-theme .login-user-screen {height: 100vh; overflow: hidden;}
.dark-theme .login-user-screen .auth-user-row {height: 100%;
    overflow: auto;}

.light-gray {
    /* background: #fff; */
    height: 100vh;
    display: flex;
    align-items: center;
    /* height: 100%; */
}

.auth-row-col-left {
    width: 35%;
    max-width: 35%;
    flex: 0 0 35%;
}

.auth-row-col-right {
    width: 65%;
    max-width: 65%;
    flex: 0 0 65%;
}

.auth-user-row {
    display: flex;
    height: 100%;
}

.auth-page-bg {
    margin: auto;
    width: 100%;
    padding: 0px 50px;
}

.auth-page-bg .auth-bg-pd {
    padding: 20px 30px;
}

.auth-title-content .signin-title {
    font-size: 20px;
    line-height: 22px;
    color: #3e54a0;
    margin-bottom: 7px;
    font-weight: 700;
    /* font-family: 'poppins-bold'; */
}

.auth-title-content .signin-details-title {
    font-size: 15px;
    line-height: 20px;
    /* font-family: 'poppins-regular'; */
    color: var(--main-secondary);
}

.auth-title-content {
    margin-bottom: 30px;
}



.input-box-auth .label-input-title {
    color: var(--main-primary);
    /* font-family: 'poppins-medium'; */
      font-weight: 500;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 17px;
}

.input-box-auth .form-control input {
    padding: 8.95px 10px;
    border: 1px solid var(--main-border);
    font-size: 14px;
    line-height: 17px;
    /* font-family: 'poppins-regular'; */
    color: var(--main-primary);
    border-radius: 5px;
    width: 100%;
}

.input-box-auth .form-control input::placeholder,
.input-box-auth .form-control-textarea::placeholder {
    color: var(--main-secondary);
}

.input-box-auth .form-control fieldset {
    color: var(--main-primary);
    border: 0px;
}

.input-details-auth {
    margin-bottom: 5px;
}


.forgot-details-content .forgot-title-deatils {
    color: var(--main-transparent);
    font-size: 15px;
    text-decoration: none;
    /* font-family: 'poppins-medium'; */
    font-weight: 500;
}

.forgot-details-content {
    text-align: right;
}


.auth-bg-content {
    background-image: linear-gradient(45deg, rgb(8 17 47 / 70%), rgb(8 17 47 / 70%)), url('../../../assets/images/jpg/signin.jpg');
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.back-details-content .back-title-deatils {
    color: var(--main-transparent);
    font-size: 15px;
    text-decoration: none;
    /* font-family: 'poppins-medium'; */
      font-weight: 500;
}

.primary-btn-content .forget-btn {
    margin: 20px 0px 0px;
}
.primary-btn-content .forget-btn:disabled{
    color: #878585;
    cursor: not-allowed;
}
.back-details-content {
    padding: 20px 0px 0px 0px;
}

/* .scroll-content-auth {
    max-height: calc(100vh - 360px);
    overflow: auto;
    padding-right: 15px;
} */

/* width */
.scroll-content-auth::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.scroll-content-auth::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

/* Handle */
.scroll-content-auth::-webkit-scrollbar-thumb {
    background: var(--main-primary);
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

/* Handle on hover */
.scroll-content-auth::-webkit-scrollbar-thumb:hover {
    background: var(--main-primary);
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}


.pass_group_main {
    position: relative;
}

.pass_group_main .pass_position {
    margin-left: 0px;
}

.pass_group_main .custom_form_control {
    padding-right: 0px;
    width: 100%;
}

.pass_group_main .custom_form_control input {
    padding-right: 45px;
}

.pass_group_main .icon_end_icon {
    position: absolute;
    right: 16px;
}

.pass_group_main .pass_position svg {
    color: #9b9b9c;
}

.condition-content {
    margin: 0px 0px 20px;
}

.condition-content .condition-text {
    color: var(--main-secondary);
    font-size: 14px;
    line-height: 21px;
    /* font-family: 'poppins-regular'; */
}

.condition-content .condition-span {
    color: #3e54a0;
    font-size: 14px;
    line-height: 21px;
    /* font-family: 'poppins-medium'; */
    font-weight: 500;
    text-decoration: none;
}

.checkbox-main .checkbox-lable {
    color: var(--main-secondary);
    /* font-family: 'poppins-regular'; */
    margin-left: 0px;
    margin-right: 0px;
}

.checkbox-main {
    display: flex;
    margin: 20px 0px 20px;
}

.checkbox-main .MuiButtonBase-root {
    margin-right: 10px;
    padding: 0px;
}


.checkbox-lable .MuiFormControlLabel-label {
    /* font-family: 'Poppins-Regular'; */
    font-size: 14px;
    line-height: 21px;
}

.captach-main {
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-top: 15px;
    padding: 10px 15px;
    background-color: #a9a5a529;
    max-width: max-content;
}

.admin-captach-main {
    padding: 0px;
    background-color: transparent;
}

.captach-icon-box {
    display: flex;
}

.captach-main .captch-text {
    color: var(--main-secondary);
    font-size: 14px;
    line-height: 21px;
    /* font-family: 'poppins-regular'; */
    margin-left: 15px;
}

.captch-icon {
    height: 40px;
    width: 40px;
    object-fit: contain;
}

.MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--main-border) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #171c3600 inset;
    transition: background-color 5000s ease-in-out 0s;
    border-radius: 5px !important;
    -webkit-text-fill-color: var(--main-primary);
}

.login-content {
    margin: auto;
    width: 100%;
    max-width: 500px;
    padding: 0px;
}

.primary-btn-content .login-btn {
    width: 100%;
}   
.primary-btn-content .login-btn:disabled{
    color: #878585;
    cursor: not-allowed;
}
.login-captach-main {
    margin: 15px 0px 20px;
    max-width: 100%;
}

.primary-btn-content .submit-btn {
    margin: 15px 0px;
    width: 100%;
}

.otp-input-box {
    margin-bottom: 20px;
}
.otp-input-box div{
    justify-content: center;
    gap: 20px;
}
.otp-input-box input {
    width: 100% !important;
    height: 1.4375em;
    padding: 29.5px 0px;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    display: inline-flex;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-color: rgba(0, 0, 0, 0.23);
}
.country-dropdown {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9;
}

.number-field-flex .number-field-text {
    position: absolute;
    top: 11px;
    color: var(--main-secondary);
    font-size: 14px;
    line-height: 17px;
    /* font-family: 'poppins-regular'; */
    right: 10px;
}

.number-field-flex {
    position: relative;
}

.number-field-flex .form-control input {
    padding-left: 70px;
}

.input-box-auth .dropdown-box .form-control fieldset {
    color: var(--main-primary);
    border: 1px solid var(--main-border);
}
.input-box-auth .country-dropdown .form-control fieldset {
    color: var(--main-primary);
    border: 0px;
}
.generate-password{
    display: flex;
}
.generate-password .generate-button{
    text-transform: capitalize;
    
}
.generate-password .suggested-password{
    padding: 7px;
    font-size: 13px;
    line-height: 21px;
    color:  var(--main-secondary);
}
.primary-btn-content .register-btn:disabled{
    color: #878585;
    cursor: not-allowed;
}
.remeber-forgot-box{
    justify-content: space-between;
    display: flex;
    align-items: center;
}
.remember-label{
    color: var(--main-secondary);
    /* font-family: 'poppins-regular'; */
    margin-left: 0px;
    margin-right: 0px;
}

.input-box-auth .country-form-control .MuiInputBase-root::before , .input-box-auth .country-form-control .MuiInputBase-root::after{
    border-bottom: 0px;
}
.country-form-control .MuiButtonBase-root {
    padding: 0px;
    position: absolute;
    left: 10px;
}
.country-form-control .MuiInputAdornment-root{
    margin-right: 0px;
}
.input-box-auth .country-form-control input{
    padding-left: 50px;
}
.input-box-auth .country-form-control .MuiInputBase-root::before, .input-box-auth .country-form-control .MuiInputBase-root::after {
    border-bottom: 0px !important;
}

.account-setting-card{
    max-width: 700px;
}
.back-text-main{
    margin: 0 0px 0px;
}
.back-text-main .back-text{
    color: var(--main-secondary);
    font-size: 14px;
    line-height: 21px;
    /* font-family: 'poppins-regular'; */
    text-decoration: none;
}
.auth-back-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
