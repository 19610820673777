.media-section .media-upload-btn-main{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.media-section .media-upload-btn-main .media-upload-btn,
.media-section .media-upload-btn-main .media-upload-btn:hover {
      background-color: var(--main-transparent);
      padding: 10px;
      height: 40px; 
       width: 0px;
      /* margin-top: 10px; */
}
.media-section .media-upload-btn-main .choose-img-text{
    margin: 10px 0px 0px 0px ;
}
