.overlay-main {
      position: relative;
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
}

.component-loader {
      height: calc(100vh - 110px);  
}


.pulse-container {
      width: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.pulse-bubble {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #3e54a0;
}

.pulse-bubble-1 {
      animation: pulse .4s ease 0s infinite alternate;
}

.pulse-bubble-2 {
      animation: pulse .4s ease .2s infinite alternate;
}

.pulse-bubble-3 {
      animation: pulse .4s ease .4s infinite alternate;
}

@keyframes pulse {
      from {
            opacity: 1;
            transform: scale(1);
      }

      to {
            opacity: .25;
            transform: scale(.75);
      }
}