.userlist-inner-btn-flex {
  display: flex;
}

.user-search-box .form-control input::placeholder {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  /* font-family: "Poppins-Regular"; */
  opacity: 1;
}

.search-grey-img {
  position: absolute;
  left: 13px;
  top: 16px;
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.user-search-box .form-control input {
  padding: 10px 13px 10px 35px;
}

.userlist-btn-flex {
  display: flex;
  /* gap: 5px; */
}

.user-search-box {
  max-width: 300px;
  position: relative;
  margin-left: 10px;
}
.user-search-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}


@media (min-width: 767px) {
  .user-published-projects-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
  .user-published-projects-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
        width: 5% !important;
        min-width: 5% !important;
        max-width: 5% !important;
  }
  .user-published-projects-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
  .user-published-projects-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
  }
  .user-published-projects-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
  .user-published-projects-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
        width: 10% !important;
        min-width: 10% !important;
        max-width: 10% !important;
  }
  .user-published-projects-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
  .user-published-projects-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
        width: 10% !important;
        min-width: 10% !important;
        max-width: 10% !important;
  }
  .user-published-projects-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
  .user-published-projects-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
        width: 10% !important;
        min-width: 10% !important;
        max-width: 10% !important;
  }
  .user-published-projects-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
  .user-published-projects-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
        width: 10% !important;
        min-width: 10% !important;
        max-width: 10% !important;
  }
  .user-published-projects-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7),
  .user-published-projects-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
        width: 10% !important;
        min-width: 10% !important;
        max-width: 10% !important;
  }
  .user-published-projects-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(8),
  .user-published-projects-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(8) {
        width: 10% !important;
        min-width: 10% !important;
        max-width: 10% !important;
  }
  .user-published-projects-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(9),
  .user-published-projects-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(9) {
        width: 10% !important;
        min-width: 10% !important;
        max-width: 10% !important;
  }
  .user-published-projects-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(10),
  .user-published-projects-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(10) {
        width: 10% !important;
        min-width: 10% !important;
        max-width: 10% !important;
  }
  .user-published-projects-wrape .data-table-main .MuiDataGrid-root {min-width: 1200px;}



  .user-draft-project-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
  .user-draft-project-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
        width: 5% !important;
        min-width: 5% !important;
        max-width: 5% !important;
  }
  .user-draft-project-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
  .user-draft-project-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
        width: 33% !important;
        min-width: 33% !important;
        max-width: 33% !important;
  }
  .user-draft-project-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
  .user-draft-project-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
        width: 10% !important;
        min-width: 10% !important;
        max-width: 10% !important;
  }
  .user-draft-project-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
  .user-draft-project-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
        width: 13% !important;
        min-width: 13% !important;
        max-width: 13% !important;
  }
  .user-draft-project-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
  .user-draft-project-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
  }
  .user-draft-project-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
  .user-draft-project-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
        width: 12% !important;
        min-width: 12% !important;
        max-width: 12% !important;
  }
  .user-draft-project-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7),
  .user-draft-project-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
        width: 13% !important;
        min-width: 13% !important;
        max-width: 13% !important;
  }
}

@media (max-width: 767px) {
  .user-published-projects-wrape .data-table-main .MuiDataGrid-root {min-width: 1010px;}
  .user-published-projects-wrape div[data-field=S_no] {
    width: 5% !important;
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .user-published-projects-wrape div[data-field=project_title] {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-published-projects-wrape div[data-field=tags] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-published-projects-wrape div[data-field=created_at] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-published-projects-wrape div[data-field=published_at] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-published-projects-wrape div[data-field=project_thumbnail] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-published-projects-wrape div[data-field=serial_number] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-published-projects-wrape div[data-field=start_date] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-published-projects-wrape div[data-field=end_date] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-published-projects-wrape div[data-field=action] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }



  .user-draft-project-wrape .data-table-main .MuiDataGrid-root {min-width: 767px;}
  .user-draft-project-wrape div[data-field=S_no] {
    width: 5% !important;
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .user-draft-project-wrape div[data-field=project_title] {
    width: 17% !important;
    min-width: 17% !important;
    max-width: 17% !important;
  }
  .user-draft-project-wrape div[data-field=tags] {
    width: 12% !important;
    min-width: 12% !important;
    max-width: 12% !important;
  }
  .user-draft-project-wrape div[data-field=project_thumbnail] {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-draft-project-wrape div[data-field=created_at] {
    width: 18% !important;
    min-width: 18% !important;
    max-width: 18% !important;
  }
  .user-draft-project-wrape div[data-field=updated_at] {
    width: 18% !important;
    min-width: 18% !important;
    max-width: 18% !important;
  }
  .user-draft-project-wrape div[data-field=action] {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
}

@media (max-width: 600px) {
  .user-draft-project-wrape .data-table-main .MuiDataGrid-root {min-width: 710px;}
  .user-draft-project-wrape div[data-field=project_title] {
    width: 19% !important;
    min-width: 19% !important;
    max-width: 19% !important;
  }
  .user-draft-project-wrape div[data-field=tags] {
    width: 14% !important;
    min-width: 14% !important;
    max-width: 14% !important;
  }
  .user-draft-project-wrape div[data-field=project_thumbnail] {
    width: 17% !important;
    min-width: 17% !important;
    max-width: 17% !important;
  }
  .user-draft-project-wrape div[data-field=created_at] {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-draft-project-wrape div[data-field=updated_at] {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
}

.user-published-projects-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {white-space: wrap;}









.user-draft-project-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {white-space: wrap;}
/* .user-draft-project-wrape .data-table-main .MuiDataGrid-root {min-width: 1100px;} */