@media screen and (min-width: 1600px) and (max-width: 1920px) {
}

@media screen and (min-width: 1366px) and (max-width: 1599px) {
}

@media screen and (min-width: 1280px) and (max-width: 1365px) {
}

@media screen and (min-width: 992px) and (max-width: 1279px) {
  .auth-page-bg {
    padding: 0px 0px;
  }
}

@media screen and (max-width: 991px) {
  .admin-sidebar-deactive .user-sidebar-logo-text {
    display: block;
  }
  .dashboard-left-main {
    flex: 0 1;
    max-width: 0;
    width: 0;
  }

  .admin-sidebar-deactive .user-sidebar-icons {
    margin-right: 8px;
  }

  .admin-sidebar-deactive .sidebar-link-span {
    display: block;
  }

  .admin-sidebar-deactive .user-sidebar-main {
    left: -500px;
  }

  .user-header-main {
    left: 0;
  }

  .close-icon {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .auth-page-bg {
    padding: 0px 0px;
  }
}

@media (max-width: 767px) {
  .admin-sidebar-deactive .user-sidebar-main {
    left: 0px;
    width: 290px;
  }
  .auth-row-col-left {
    display: none;
  }

  .auth-row-col-right {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .login-content {
    max-width: 100%;
  }

  .auth-page-bg {
    padding: 0px 0px;
  }

  .user-sidebar-list-main {
    height: calc(100vh - 150px);
  }
}

@media screen and (min-width: 280px) and (max-width: 374px) {
}
