.user-dashboard-inner-box {
      box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 3px -2px, rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px;
      max-width: 100%;
      width: 100%;
      height: 100px;
      border-radius: 6px;
      background-color: rgb(255, 255, 255);
      padding: 15px 20px;
}

.dark-theme .user-dashboard-inner-box {
      background: var(--stat-box);
}

.user-dashboard-inner-box .user-dash-text {
      color: var(--main-secondary);
      font-size: 14px;
      line-height: 22px;
      /* text-align: center; */
      /* font-family: 'Poppins-Regular'; */
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      /* margin-bottom: 15px; */
}

.user-dashboard-inner-box .user-dash-price {
      color: #3e54a0;
      font-size: 23px;
      line-height: 34px;
      /* text-align: center; */
      /* font-family: 'Poppins-Medium'; */
      font-weight: 500;      
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
}

.user-dashboad-row {
      margin-bottom: 20px;
}

.display-row>* {
      padding: 0px 8px;
}

.display-row {
      margin-left: -8px;
      margin-right: -8px;
}

.stat-boxes .display-row .grid-column {
      padding: 8px;
}

.recent-project-row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--main-border);
      padding: 10px 5px;
      margin-bottom: 5px;
}

.recent-project-row:last-child {
      border-bottom: 0px;
      margin-bottom: 0px;
}

.recent-project-left-main {
      display: flex;
      width: 100%;
      align-items: center;
}

.recent-project-img {
      height: 40px;
      width: 40px;
      object-fit: contain;
      margin-right: 20px;
}

.recent-project-left-main .recent-project-title {
      font-size: 17px;
      line-height: 26px;
      /* font-family: 'poppins-semibold'; */
      font-weight: 700;
      color: #3e54a0;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
}

.recent-project-main-box {
      box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 3px -2px, rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px;
      padding: 15px;
      background-color: rgb(255, 255, 255);
      border-radius: 6px;
}

.recent-project-right-main .recent-project-view-link {
      font-size: 16px;
      line-height: 29px;
      /* font-family: 'poppins-medium'; */
      font-weight: 500;
      color: var(--main-secondary);
      text-decoration: none;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 20px;
}

.dashboard-icon {
      height: 50px;
      width: 50px;
      object-fit: contain;
}

.user-dashbaord-card-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
}

.user-dashboard-box {
      cursor: pointer;
}
.recent-project-table-main .table {
      min-width: 450px;
}

.recent-project-table-main .table .table-th:nth-child(1),
.recent-project-table-main .table .table-td:nth-child(1) {
      width: 5%;
}

.recent-project-table-main .table .table-th:nth-child(2),
.recent-project-table-main .table .table-td:nth-child(2) {
      width: 5%;
}

.recent-project-table-main .table .table-th:nth-child(3),
.recent-project-table-main .table .table-td:nth-child(3) {
      width: 5%;
}
.user-dashbaord-card-left .user-dash-title{
      color: var(--main-primary);
      font-size: 17px;
      line-height: 22px;
      /* text-align: center; */
      /* font-family: 'poppins-medium'; */
      font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}