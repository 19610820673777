@media screen and (min-width: 1600px) and (max-width: 1920px) {}

@media screen and (min-width: 1366px) and (max-width: 1599px) {}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

@media screen and (min-width: 992px) and (max-width: 1279px) {}

@media screen and (max-width: 991px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media (max-width: 767px) {
      .file-upload-btn-main .choose-img-text {
            font-size: 13px;
            line-height: 19px;
            margin: 10px 0px 10px 10px;
      }

      .file-upload-btn-main {
            align-items: self-start;
            flex-direction: column;
      }

      .form-group .analytic-date-picker-main {
            margin-left: 0;
      }

      /* .file-upload-btn-main .file-upload-btn,
      .file-upload-btn-main .file-upload-btn:hover {
            height: 40px;
      } */
      .add-tags-box{
            margin-bottom: 10px;
      }
}

@media screen and (min-width: 280px) and (max-width:374px) {}