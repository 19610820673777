.print-icons {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.print-icon-box {
  margin: 0px 10px 0px 0px;
  height: 43px;
  width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--main-border);
  cursor: pointer;
  border-radius: 6px;
}



@media (min-width: 767px) {
  .user-project-report-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
  .user-project-report-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
    width: 5% !important;
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .user-project-report-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
  .user-project-report-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-project-report-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
  .user-project-report-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
    width: 12% !important;
    min-width: 12% !important;
    max-width: 12% !important;
  }
  .user-project-report-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
  .user-project-report-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .user-project-report-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
  .user-project-report-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-project-report-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
  .user-project-report-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-project-report-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7),
  .user-project-report-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-project-report-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(8),
  .user-project-report-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(8) {
    width: 8% !important;
    min-width: 8% !important;
    max-width: 8% !important;
  }
  .user-project-report-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(9),
  .user-project-report-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(9) {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-project-report-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(10),
  .user-project-report-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(10) {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-project-report-wrape .data-table-main .MuiDataGrid-root {min-width: 1200px;}
}



@media (max-width: 767px) {
  .user-project-report-wrape .data-table-main .MuiDataGrid-root {min-width: 1010px;}
  .user-project-report-wrape div[data-field=id] {
    width: 5% !important;
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .user-project-report-wrape div[data-field=ProjectID] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-project-report-wrape div[data-field=ProjectName] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-project-report-wrape div[data-field=Description] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-project-report-wrape div[data-field=Createdat] {
    width: 12% !important;
    min-width: 12% !important;
    max-width: 12% !important;
  }
  .user-project-report-wrape div[data-field=Publishedat] {
    width: 13% !important;
    min-width: 13% !important;
    max-width: 13% !important;
  }
  .user-project-report-wrape div[data-field=Serialcode] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-project-report-wrape div[data-field=ProjectThumbnail] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-project-report-wrape div[data-field=StartDate] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
  .user-project-report-wrape div[data-field=EndDate] {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
}


.user-project-report-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {white-space: wrap;}

.custom-header {
  position: relative;
}

/* .custom-header:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  background-color: black;
  color: white;
  padding: 4px;
  border-radius: 50px;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0.8;
  z-index: 1;
} */