.successfully-card-main .upload-sucsess-text {
      color: var(--main-dark-blue);
      font-size: 17px;
      line-height: 25px;
      /* font-family: 'Poppins-SemiBold'; */
      font-weight: 700;
      margin-bottom: 20px;
}
.successfully-icon{
      height: 50px;
      width: 50px;
      object-fit: contain;
}
.successfully-card-main{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 200px;
}