 .user-page-title {
    color: #3e54a0;
    font-size: 21px;
    line-height: 34px;
    /* font-family: 'Poppins-SemiBold'; */
    font-weight: 700;
    margin-bottom: 15px;
}
/* .user-management-table-main .table {
    min-width: 1000px;
} */



.user-management-table-main-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell-activate-view {
    border-right: 2px solid black;
    padding: 0 10px;
}
.user-management-table-main-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell-activate-delete {    
    padding: 0 10px;
}
.user-management-search  {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.user-search-box .form-group input::placeholder {
    color: #6B7280;
    font-size: 12px;
    line-height: 20px;
    /* font-family: 'Poppins-Regular'; */
    opacity: 1;
}
.form-group .search-icon-image{
    position: absolute;
      left: 13px;
      top: 21px;
      height: 14px;
      width: 14px;
      margin-right: 8px;

}

/* .user-management-search {
    display: flex;
} */

.form-group .MuiInputBase-input {
    padding-left: 35px;
}

.admin-pagination-box {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}



@media (min-width:767px) {
    .user-management-table-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
    .user-management-table-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
        width: 5% !important;
        min-width: 5% !important;
        max-width: 5% !important;
    }

    .user-management-table-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
    .user-management-table-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
        width: 18% !important;
        min-width: 18% !important;
        max-width: 18% !important;
    }

    .user-management-table-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
    .user-management-table-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
        width: 18% !important;
        min-width: 18% !important;
        max-width: 18% !important;
    }

    .user-management-table-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
    .user-management-table-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
        width: 25% !important;
        min-width: 25% !important;
        max-width: 25% !important;
    }

    .user-management-table-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
    .user-management-table-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
    }

    .user-management-table-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
    .user-management-table-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
        width: 9% !important;
        min-width: 9% !important;
        max-width: 9% !important;
    }

    .user-management-table-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
    .user-management-table-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
        width: 10% !important;
        min-width: 10% !important;
        max-width: 10% !important;
    }
}

@media (max-width: 767px) {    
    .menu-report-section.common-card.user-management-wrape .data-table-main .MuiDataGrid-root {
        min-width: 767px;
    }
    div[data-field=serialNumber] {
        width: 5% !important;
        max-width: 5% !important;
        min-width: 5% !important;
    }
    div[data-field=firstName] {
        width: 14% !important;
        max-width: 14% !important;
        min-width: 14% !important;
    }
    div[data-field=lastName] {
        width: 14% !important;
        max-width: 14% !important;
        min-width: 14% !important;
    }
    div[data-field=email] {
        width: 30% !important;
        max-width: 30% !important;
        min-width: 30% !important;
    }
    div[data-field=phoneNumber] {
        width: 15% !important;
        max-width: 15% !important;
        min-width: 15% !important;
    }
    div[data-field=isActive] {
        width: 10% !important;
        max-width: 10% !important;
        min-width: 10% !important;
    }
    div[data-field=action] {
        width: 12% !important;
        max-width: 12% !important;
        min-width: 12% !important;
    }
}