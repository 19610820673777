.qr-code-published-table .table {
      min-width: 1000px;
}

.qr-code-published-table .table .table-th:nth-child(1),
.qr-code-published-table .table .table-td:nth-child(1) {
      width: 5%;
}

.qr-code-published-table .table .table-th:nth-child(2),
.qr-code-published-table .table .table-td:nth-child(2) {
      width: 5%;
}

.qr-code-published-table .table .table-th:nth-child(3),
.qr-code-published-table .table .table-td:nth-child(3) {
      width: 5%;
}

.qr-code-published-table .table .table-th:nth-child(4),
.qr-code-published-table .table .table-td:nth-child(4) {
      width: 5%;
}

.qr-code-published-table .table .table-th:nth-child(5),
.qr-code-published-table .table .table-td:nth-child(5) {
      width: 5%;
}

.qr-code-published-table .table .table-th:nth-child(6),
.qr-code-published-table .table .table-td:nth-child(6) {
      width: 5%;
}


.qr-code-non-published-table .table{
      min-width: 1000px;
}
.qr-code-non-published-table .table .table-th:nth-child(1),
.qr-code-non-published-table .table .table-td:nth-child(1) {
      width: 5%;
}
.qr-code-non-published-table .table .table-th:nth-child(2),
.qr-code-non-published-table .table .table-td:nth-child(2) {
      width: 5%;
}
.qr-code-non-published-table .table .table-th:nth-child(3),
.qr-code-non-published-table .table .table-td:nth-child(3) {
      width: 5%;
}
.qr-code-non-published-table .table .table-th:nth-child(4),
.qr-code-non-published-table .table .table-td:nth-child(4) {
      width: 5%;
}
.qr-code-non-published-table .table .table-th:nth-child(5),
.qr-code-non-published-table .table .table-td:nth-child(5) {
      width: 5%;
}
.qr-code-non-published-table .table .table-th:nth-child(6),
.qr-code-non-published-table .table .table-td:nth-child(6) {
      width: 5%;
}