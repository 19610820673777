
 .user-page-title {
    color: #3e54a0;
    font-size: 21px;
    line-height: 34px;
    /* font-family: 'Poppins-SemiBold'; */
    font-weight: 700;
    margin-bottom: 15px;
}
 .user-details-dash-box .user-details-box {
    width: 1000px; /* Set the width of the rectangle */
    height: 250px; /* Set the height of the rectangle */
   
    border: 2px solid black; /* Set the border style and color */
    display: flex;
    margin-top: 10px;
    
}
.user-details-box .costom-detail {
    margin-left: 10px;
}

.user-details-flex {
    display: flex;
    margin-bottom: 15px;
}
.user-details-flex .user-details-lable{
    color: #3e54a0;
    font-size: 16px;
    line-height: 20px;;
    /* font-family: 'Poppins-SemiBold'; */
    font-weight: 700;
    max-width: 150px;
    width: 100%;
    flex: 0 0 150px;
    

}
.user-details-flex .user-details-data {
    color: #6B7280;
    font-size: 16px;
    line-height: 20px;
    /* font-family: 'poppins-regular'; */
    flex: 1;
    width: calc(100% - 150px);
    word-wrap: break-word;
}



.user-details-main .user-details {

    padding: 10px;


}
/* .user-details-main .user-details-heading {
    font-weight: bold;
} */

.user-details-title {
    padding-top:20px ;
}


/* table */

.admin-dash-box .user-details-table-main .table{
    min-width: 900px;
    padding-top:20px 

}

@media (min-width: 767px) { 
    /* project table start */
    .project-tab-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
    .project-tab-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
        width: 5% !important;
        min-width: 5% !important;
        max-width: 5% !important;
    }
    .project-tab-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
    .project-tab-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
    }
    .project-tab-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
    .project-tab-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
        width: 12% !important;
        min-width: 12% !important;
        max-width: 12% !important;
    }
    .project-tab-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
    .project-tab-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
        width: 12% !important;
        min-width: 12% !important;
        max-width: 12% !important;
    }
    .project-tab-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
    .project-tab-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
        width: 10% !important;
        min-width: 10% !important;
        max-width: 10% !important;
    }
    .project-tab-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
    .project-tab-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
        width: 10% !important;
        min-width: 10% !important;
        max-width: 10% !important;
    }
    .project-tab-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7),
    .project-tab-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
        width: 13% !important;
        min-width: 13% !important;
        max-width: 13% !important;
    }
    .project-tab-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(8),
    .project-tab-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(8) {
        width: 12% !important;
        min-width: 12% !important;
        max-width: 12% !important;
    }
    .project-tab-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(9),
    .project-tab-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(9) {
        width: 11% !important;
        min-width: 11% !important;
        max-width: 11% !important;
    }
    /* project table end */

    /* project history table start */
    .project-history-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
    .project-history-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
        width: 5% !important;
        min-width: 5% !important;
        max-width: 5% !important;
    }

    .project-history-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
    .project-history-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
        width: 20% !important;
        min-width: 20% !important;
        max-width: 20% !important;
    }

    .project-history-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
    .project-history-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
        width: 19% !important;
        min-width: 19% !important;
        max-width: 19% !important;
    }

    .project-history-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
    .project-history-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
        width: 19% !important;
        min-width: 19% !important;
        max-width: 19% !important;
    }

    .project-history-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
    .project-history-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
        width: 22% !important;
        min-width: 22% !important;
        max-width: 22% !important;
    }
    .project-history-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
    .project-history-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
    }    
    .project-tab-wrape .data-table-main .MuiDataGrid-root {min-width: 1200px;}   
}


@media (max-width: 767px) {
    .project-tab-wrape .data-table-main .MuiDataGrid-root {
        min-width: 920px;
    }
    .project-tab-wrape div[data-field=id] {
        width: 5% !important;
        min-width: 5% !important;
        max-width: 5% !important;
    }
    .project-tab-wrape div[data-field=ProjectTitle] {
        width: 13% !important;
        min-width: 13% !important;
        max-width: 13% !important;
    }
    .project-tab-wrape div[data-field=ProjectTags] {
        width: 11% !important;
        min-width: 11% !important;
        max-width: 11% !important;
    }
    .project-tab-wrape div[data-field=PublishDate] {
        width: 12% !important;
        min-width: 12% !important;
        max-width: 12% !important;
    }
    .project-tab-wrape div[data-field=ORgenerated] {
        width: 12% !important;
        min-width: 12% !important;
        max-width: 12% !important;
    }
    .project-tab-wrape div[data-field=Serialnumber] {
        width: 11% !important;
        min-width: 11% !important;
        max-width: 11% !important;
    }
    .project-tab-wrape div[data-field=ProjThumbnails] {
        width: 12% !important;
        min-width: 12% !important;
        max-width: 12% !important;
    }
    .project-tab-wrape div[data-field=startDate] {
        width: 12% !important;
        min-width: 12% !important;
        max-width: 12% !important;
    }
    .project-tab-wrape div[data-field=endDate] {
        width: 12% !important;
        min-width: 12% !important;
        max-width: 12% !important;
    }


    .project-history-wrape .data-table-main .MuiDataGrid-root {
        min-width: 620px;
    }
    .project-history-wrape div[data-field=id] {
        width: 5% !important;
        min-width: 5% !important;
        max-width: 5% !important;
    }
    .project-history-wrape div[data-field=paymentId] {
        width: 20% !important;
        min-width: 20% !important;
        max-width: 20% !important;
    }
    .project-history-wrape div[data-field=mode] {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
    }
    .project-history-wrape div[data-field=date] {
        width: 20% !important;
        min-width: 20% !important;
        max-width: 20% !important;
    }
    .project-history-wrape div[data-field=subscriptionPlan] {
        width: 23% !important;
        min-width: 23% !important;
        max-width: 23% !important;
    }
    .project-history-wrape div[data-field=amount] {
        width: 17% !important;
        min-width: 17% !important;
        max-width: 17% !important;
    }
}




.project-tab-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {white-space: wrap;}




















.user-details-search {
    padding-top:20px ;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    
}
.user-detailsSearch-box .form-group input::placeholder {
    color: #6B7280;
    font-size: 12px;
    line-height: 20px;
    /* font-family: 'Poppins-Regular'; */
    opacity: 1;
}

.form-group-userdetails .userDetails-search-icon-image{
    position: absolute;
    left: 13px;
    top: 21px;
    height: 14px;
    width: 14px;
    margin-right: 8px;

}

.campaigns-section-main{
    margin-top: 40px;
}