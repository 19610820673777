.coustum-payment-table .payment-table .table {
    min-width: 1041px;
}

@media (min-width: 767px) { 
      .admin-payment .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
      .admin-payment .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
            width: 5% !important;
            min-width: 5% !important;
            max-width: 5% !important;
      }
      .admin-payment .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
      .admin-payment .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
            width: 17% !important;
            min-width: 17% !important;
            max-width: 17% !important;
      }
      .admin-payment .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
      .admin-payment .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
            width: 15% !important;
            min-width: 15% !important;
            max-width: 15% !important;
      }
      .admin-payment .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
      .admin-payment .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
            width: 31% !important;
            min-width: 31% !important;
            max-width: 31% !important;
      }
      .admin-payment .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
      .admin-payment .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
            width: 17% !important;
            min-width: 17% !important;
            max-width: 17% !important;
      }
      .admin-payment .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
      .admin-payment .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
            width: 15% !important;
            min-width: 15% !important;
            max-width: 15% !important;
      }
}

@media (max-width: 767px) {
      .admin-payment .data-table-main .MuiDataGrid-root {min-width: 767px;}

      .admin-payment div[data-field=id] {
            width: 5% !important;
            min-width: 5% !important;
            max-width: 5% !important;
      }
      .admin-payment div[data-field=paymentID] {
            width: 17% !important;
            min-width: 17% !important;
            max-width: 17% !important;
      }
      .admin-payment div[data-field=date] {
            width: 15% !important;
            min-width: 15% !important;
            max-width: 15% !important;
      }
      .admin-payment div[data-field=userEmail] {
            width: 28% !important;
            min-width: 28% !important;
            max-width: 28% !important;
      }
      .admin-payment div[data-field=mode] {
            width: 15% !important;
            min-width: 15% !important;
            max-width: 15% !important;
      }
      .admin-payment div[data-field=amount] {
            width: 20% !important;
            min-width: 20% !important;
            max-width: 20% !important;
      }
}

@media (max-width: 600px) {
      .admin-payment .data-table-main .MuiDataGrid-root {min-width: 610px;}
      .admin-payment div[data-field=date] {
            width: 18% !important;
            min-width: 18% !important;
            max-width: 18% !important;
      }
      .admin-payment div[data-field=amount] {
            width: 17% !important;
            min-width: 17% !important;
            max-width: 17% !important;
      }
}

.coustum-payment-table .coustom-search  {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}