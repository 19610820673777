@media screen and (min-width: 1600px) and (max-width: 1920px) {}

@media screen and (min-width: 1366px) and (max-width: 1599px) {}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

@media screen and (min-width: 992px) and (max-width: 1279px) {}

@media screen and (max-width: 991px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media (max-width: 767px) {
      .media-btns-main .media-btns {
            font-size: 12px;
            line-height: 19px;
            margin-right: 5px;
      }
}

@media screen and (min-width: 280px) and (max-width:374px) {}