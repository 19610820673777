.bulk-successfully-inner {
    min-width: 500px;
}

.bulk-successfully-card-main .successfully-icon {
    height: 50px;
    width: 100%;
}

.bulk-successfully-inner .save-publish-modal-content {
    text-align: center;
}
.common-modal .bulk-successfully-inner{
    min-width: 441px;
}