.subscription-table-main .table-container {
    padding-bottom: 7px;
    background-color: transparent;
    box-shadow: none;
    overflow: auto;
    max-width: calc(100vw - 0px);
}


.subscription-table-main .table-th {
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    font-weight: 700;
    background: var(--main-dark-blue);
    padding: 15px 15px;
    white-space: nowrap;
}

.subscription-table-main .table-td {
    font-size: 12px;
    line-height: 10px;
    color: var(--main-secondary);
    display: table-cell;
    /* font-family: "Poppins-Regular"; */
    border-bottom: 1px solid var(--main-border);
     padding: 9px 15px;
}

.table-section {
    background-color: #f0f3f8;
    border-radius: 6px;
    padding: 15px;
}

.subscription-table-main .table {
    /* background: #f0f3f8; */
    border-collapse: initial;
    border-radius: 0;
    border-spacing: 0 0;
}
/* width */
.subscription-table-main .table-container::-webkit-scrollbar {
    width: 2px;
    height: 5px;
}
.table-icon-flex {
    display: flex;
    align-items: center;
}
/* Track */
.subscription-table-main .table-container::-webkit-scrollbar-track {
    background: #D1D5DB;
    border-radius: 5px;
}

/* Handle */
.subscription-table-main .table-container::-webkit-scrollbar-thumb {
    background: #8888885c;
}

/* Handle on hover */
.subscription-table-main .table-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Add Edit */
/* .subscription-add-Edit-page .create-new-project-card */
.subscription-create-new-project-card{
    max-width: 100%;
    width: 100%;
}
.subscription-flex{
    display: flex;
    align-items: center;
}
.subscription-flex-left{
max-width: 30%;
width: 100%;
flex: 0 0 30%;
}
.subscription-flex-right{
flex: 1;
}

.common-modal .view-subscription-modal-inner{
min-width: 700px;
}


@media (min-width: 767px) {
    .admin-subscrition-list-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
    .admin-subscrition-list-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
        width: 5% !important;
        min-width: 5% !important;
        max-width: 5% !important;
    }
    .admin-subscrition-list-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
    .admin-subscrition-list-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
        width: 33% !important;
        min-width: 33% !important;
        max-width: 33% !important;
    }
    .admin-subscrition-list-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
    .admin-subscrition-list-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
    }
    .admin-subscrition-list-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
    .admin-subscrition-list-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
        width: 17% !important;
        min-width: 17% !important;
        max-width: 17% !important;
    }
    .admin-subscrition-list-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
    .admin-subscrition-list-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
    }
    .admin-subscrition-list-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
    .admin-subscrition-list-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
    }
}

@media screen and (max-width: 799px) {
    .common-modal .view-subscription-modal-inner {
        min-width: 90%;
    }
}

@media (max-width: 767px) {
    .admin-payment .data-table-main .MuiDataGrid-root {min-width: 767px;}
    .admin-subscrition-list-wrape div[data-field=id] {
        width: 5% !important;
        min-width: 5% !important;
        max-width: 5% !important;
    }
    .admin-subscrition-list-wrape div[data-field=name] {
        width: 25% !important;
        min-width: 25% !important;
        max-width: 25% !important;
    }
    .admin-subscrition-list-wrape div[data-field=price] {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
    }
    .admin-subscrition-list-wrape div[data-field=activeProjects] {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
    }
    .admin-subscrition-list-wrape div[data-field=activeQR] {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
    }
    .admin-subscrition-list-wrape div[data-field=action] {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
    }
}

@media (max-width: 600px) {
    .admin-subscrition-list-wrape  .data-table-main .MuiDataGrid-root {
        min-width: 610px;
        width: 610px;
    }
    .admin-subscrition-list-wrape div[data-field=id] {
        width: 5% !important;
        min-width: 5% !important;
        max-width: 5% !important;
    }
    .admin-subscrition-list-wrape div[data-field=name] {
        width: 27% !important;
        min-width: 27% !important;
        max-width: 27% !important;
    }
    .admin-subscrition-list-wrape div[data-field=price] {
        width: 17% !important;
        min-width: 17% !important;
        max-width: 17% !important;
    }
    .admin-subscrition-list-wrape div[data-field=activeProjects] {
        width: 17% !important;
        min-width: 17% !important;
        max-width: 17% !important;
    }
    .admin-subscrition-list-wrape div[data-field=activeQR] {
        width: 15% !important;
        min-width: 15% !important;
        max-width: 15% !important;
    }
    .admin-subscrition-list-wrape div[data-field=action] {
        width: 19% !important;
        min-width: 19% !important;
        max-width: 19% !important;
    }
}