.table-check .MuiCheckbox-root span {
    width: 15px;
    height: 15px;
}

.table-check .MuiCheckbox-root span::before {
    width: 13px;
    height: 13px;
}

.table-check.table-check-head .MuiCheckbox-root span {
    border: 1px solid #fff;
}

.table-check.table-check-head .MuiCheckbox-root span::before {
    filter: brightness(0) invert(0.9);
}



.bulk-non-published-projects-table .table {
    min-width: 970px;
}

.bulk-non-published-projects-table .table .table-th:nth-child(1),
.bulk-non-published-projects-table .table .table-td:nth-child(1) {
    width: 5%;
}

.bulk-non-published-projects-table .table .table-th:nth-child(2),
.bulk-non-published-projects-table .table .table-td:nth-child(2) {
    width: 5%;
}

.bulk-non-published-projects-table .table .table-th:nth-child(3),
.bulk-non-published-projects-table .table .table-td:nth-child(3) {
    width: 5%;
}

.bulk-non-published-projects-table .table .table-th:nth-child(4),
.bulk-non-published-projects-table .table .table-td:nth-child(4) {
    width: 5%;
}

.bulk-non-published-projects-table .table .table-th:nth-child(5),
.bulk-non-published-projects-table .table .table-td:nth-child(5) {
    width: 5%;
}

.bulk-non-published-projects-table .table .table-th:nth-child(6),
.bulk-non-published-projects-table .table-td:nth-child(6) {
    width: 5%;
}

