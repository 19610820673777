/* start admin dashboard page structure css */

.dashboard-left-main {
      flex: 0 0 290px;
      max-width: 290px;
      width: 100%;
      transition: 0.5s all;
}

.dashboard-right-main {
      flex: 1;
}


.dashboard-containt-main {
      background-color: var(--main-bg);
      min-height: 100vh;
      padding: 90px 20px 20px 20px;
}

.dashboard-main {
      display: flex;
      width: 100%;
}

.admin-sidebar-deactive{
      flex: 0 0 90px;
      max-width: 90px;
      transition: all .5s;
      width: 100%;
}

/* .admin-sidebar-deactive .submenu-main {display: none;} */
/* end admin dashboard page structure css */






















