.media-main-card {
      border-radius: 6px;
      padding: 0px;
}

.media-img {
      height: 200px;
      width: 100%;
      object-fit: cover;
      border-radius: 6px;
}

.media-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 3px -2px, rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px;
      padding: 10px;
      border-radius: 6px;
}

.media-flex .media-size-text {
      color: var(--main-primary);
      font-size: 14px;
      /* line-height: 22px; */
      /* font-family: 'Poppins-Regular'; */
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 7px;
}

.media-flex .media-caption-text {
      color: var(--main-primary);
      font-size: 14px;
      /* line-height: 22px; */
      /* font-family: 'Poppins-SemiBold'; */
      font-weight: 700;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
}

.trash-icon {
      height: 30px;
      width: 30px;
      object-fit: contain;
}

.media-flex .media-trash-btn {
      padding: 0px;
      min-width: 30px;
}

.media-btns-main {
      margin-bottom: 20px;
      border-bottom: 1px solid var(--main-border);
}

.media-btns-main .media-btns {
      border-radius: 6px;
      margin-right: 10px;
      font-size: 14px;
      line-height: 22px;
      /* font-family: 'Poppins-Regular'; */
      color: var(--main-secondary);
      text-transform: capitalize;
}

.media-btns-main .media-btns.active {
      /* background: var(--main-dark-blue);
      ;
      color: var(--main-white); */
      /* font-family: 'Poppins-SemiBold'; */
      /* font-weight: 700; */
      color: var(--main-white);
      font-weight: 700;
      /* font-family: "Poppins-SemiBold"; */
      background: var(--main-primary);
      border-radius: 5px 5px 0px 0px;
}
