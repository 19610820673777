/* start user sidebar css */


.user-sidebar-icons {
      margin-right: 8px;
      height: 20px;
      width: 20px;
      object-fit: contain;
}

.user-sidebar-main {
      background-image: linear-gradient(rgba(34, 42, 69, 0.96), rgba(34, 42, 69, 0.96));
      height: 100vh;
      left: -500px;
      /* padding: 0px 16px; */
      position: fixed;
      top: 0px;
      width: 290px;
      z-index: 999;
      border-right: 1px solid #E5E7EB;
      transition: 0.5s all;

}

.user-sidebar-main.active {
      left: 0px;
}

.user-sidebar-deactive {
      flex: 0 0 0px;
      max-width: 0px;
      width: 100%;
      transition: 0.5s all;
}


.user-sidebar-logo-main .sidebar-close-btn {
      padding: 0px;
      min-width: 30px;
}

.user-sidebar-logo-main .user-sidebar-logo-text {
      font-size: 20px;
      line-height: 21px;
      /* font-family: 'Poppins-Bold'; */
      font-weight: 700;
      color: var(--main-white);
      margin-left: 15px;
}

.user-sidebar-logo-main {
      padding: 15px 20px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 64px;
}

.user-sidebar-list-main {
      padding: 16px 16px;
      overflow: auto;
      height: calc(100Vh - 100px);
}

.user-header-drop-main .drop-header-btn {
      padding: 0px;
      min-width: 36px;
}

.user-sidebar-list-main .user-sidebar-list {
      padding: 0px;
}

.user-sidebar-list-main .user-sidebar-listitem {
      padding: 0px;
}

.user-sidebar-list-main .user-sidebar-link {
      font-size: 13px;
      line-height: 21px;
      /* font-family: 'Poppins-Regular'; */
      color: var(--main-white);
      display: flex;
      padding: 10px 12px 10px;
      margin-bottom: 10px;
      width: 100%;
      text-decoration: none;
      cursor: pointer;
}

.user-sidebar-listitem .user-submenu-link-box {
      display: flex;
}

.submenu-main .user-submenu-link {
      display: flex;
      padding: 10px 13px 10px 13px;
}

.user-sidebar-list-main .user-sidebar-link.active {
      color: var(--main-white);
      background: linear-gradient(45deg, #3e53a0, transparent);
      border-radius: 4px;
}

.submenu-main .user-submenu-listitem {
      padding: 0px 12px 0px 0;
}

.user-sidebar-main::-webkit-scrollbar {
      width: 2px;
}


.user-sidebar-listitem .expandless-icon,
.user-sidebar-listitem .expandmore-icon {
      position: absolute;
      right: -6px;
      top: 10px;
}

.user-sidebar-submenulist .user-sidebar-link {
      margin-left: 10px;
}

.user-sidebar-submenulist .user-sidebar-listitem:first-child .user-sidebar-link {
      margin-top: 20px;
}

.user-sidebar-list-main::-webkit-scrollbar-track {
      background: #f1f1f1;
}

.user-sidebar-list-main::-webkit-scrollbar-thumb {
      background: #888;
}

.user-sidebar-list-main::-webkit-scrollbar-thumb:hover {
      background: #555;
}

.user-sidebar-list-main::-webkit-scrollbar {
      width: 2px;
}

.user-sibar-logo-flex {
      display: flex;
      align-items: center;
}

/* end user sidebar css */






/* start user header css */


.user-header-main {
      background: rgb(255, 255, 255);
      position: fixed;
      top: 0px;
      left: 290px;
      right: 0px;
      z-index: 12;
      padding: 15px 20px 13px 20px;
      border-bottom: 1px solid #E5E7EB;
      transition: 0.5s all;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px;
}

.user-header-deactive {
      left: 0px;
}



.user-header-main.active .user-sidebar-logo {
      opacity: 0;
      display: none;
}

.user-header-logo-main .user-bergur-button {
      padding: 0px;
      min-width: 30px;
}

.user-header-main.user-header-deactive .user-sidebar-logo {
      opacity: 1;
      transition: 0.5s all;
      height: 36px;
      width: 36px;
      margin-right: 10px;
}

.user-header-logo-main {
      display: flex;
}

.user-header-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.user-header-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
}

.user-header-right .user-header-icon-box {
      padding: 0px;
      min-width: 30px;
      margin-right: 10px;
}

.user-header-right .user-header-icon-box:last-child {
      margin-right: 0px;
}

.user-header-icon {
      height: 20px;
      width: 20px;
      object-fit: contain;
}
.user-header-profile-icon {
      height: 36px;
      width: 36px;
      object-fit: cover;
      border-radius: 50%;
      border: 1px solid var(--main-border);
}

.bergurmenu-icon {
      height: 25px;
      width: 30px;
      object-fit: contain;
}

.close-icon {
      display: none;
      height: 20px;
      width: 20px;
      object-fit: contain;
}

/* end user header css */





/* start header profile dropdown  */

.flex-drop-main .user-header-drop {
      font-size: 12px;
      /* font-family: 'Poppins-Regular'; */
      line-height: 18px;
      color: #6B7280;
      text-align: start;
      margin-left: 12px;
      text-transform: capitalize;
}

.flex-drop-main .header-drop-title {
      /* font-family: 'Poppins-Bold'; */
      font-weight: 700;
}

.flex-drop-main {
      display: flex;
      align-items: center;
}


.drop-header-menu .MuiPaper-rounded {
      box-shadow: 10px 10px 20px #0000001A;
      border: 1px solid #E5E7EB;
      margin-top: 13px;
      border-radius: 1px;
      min-width: 150px;
}

.drop-header {
      height: 20px;
      width: 20px;
      margin-right: 6px;
}

.drop-header-menu .drop-header-menuitem {
      font-size: 14px;
      line-height: 16px;
      color: #6B7280;
      /* font-family: 'Poppins-Regular'; */
      border-bottom: 1px solid #E5E7EB;
      padding: 11px 16px;
}

.drop-header-menu ul {
      padding: 0px;
}

/* end header profile dropdown  */


/* start auth footer social meadia */

.social-main .social-ul {
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      display: flex;
      /* margin: 20px 0px; */
      justify-content: center;
}

.social-main .social-ul .social-li {
      padding: 0px;
      margin: 0px;
      width: max-content;
}

.social-box {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 1px solid #D1D5DB;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
}

.social-icons {
      height: 15px;
      width: 15px;
      object-fit: contain;
}

.social-main .social-ul .social-li:last-child .social-box {
      margin-right: 0px;
}


.switch-lable .MuiTypography-root {
      font-size: 14px;
      line-height: 21px;
      color: #6B7280;
      /* font-family: 'Poppins-Regular'; */
}


/* end auth footer social meadia */
.user-sidebar-switch-main {
      margin-left: 20px;
}

.dark-light-btn-main .dark-light-btn {
      padding: 0px;
}

.dark-light-btn-main .dark-light-btn svg {
      color: var(--main-primary);
}

.user-sidebar-link-box {
      display: flex;
}

.user-sidebar-logo {
      height: 36px;
      width: 36px;
      object-fit: cover;
      border-radius: 50% ;
}

.admin-sidebar-deactive .user-sidebar-main {
      left: 0;
      width: 90px;
}

.user-header-deactive {
      left: 90px;
}

.admin-sidebar-deactive .sidebar-link-span {
      display: none;
}

.admin-sidebar-deactive .user-sidebar-logo-text {
      display: none;
}

.admin-sidebar-deactive .user-sidebar-listitem .expandless-icon,
.admin-sidebar-deactive .user-sidebar-listitem .expandmore-icon {
      /* display: none; */
}

.admin-sidebar-deactive .user-sidebar-icons {
      margin-right: 0px;
}
.admin-sidebar-deactive .user-sidebar-list-main .user-sidebar-link{
      /* width: auto; */
}
/* dark theme start */
.dark-theme .user-sidebar-main {background: #08112f; border-right: 1px solid var(--stat-box-border);}
.dark-theme .user-header-main {background: #08112f; border-bottom: 1px solid var(--stat-box-border);}
/* dark theme end */