.cust-acc-main .cust-accordian {
      margin-bottom: 10px;
      box-shadow: none;
      background: #0000000d;
      border-radius: 6px;
}

.cust-acc-main .cust-acc-summary {
      /* border-bottom: 1px solid #0000001f; */
}

.cust-acc-main .cust-accordian:before {
      display: none;
}

.cust-acc-main .cust-accordian .cust-acc-summary.Mui-expanded {
      min-height: 50px;
      /* border-bottom: 1px solid #0000001f; */
}

.acc-summary-title-box .acc-summary-title-text {
      color: var(--main-primary);
    font-size: 15px;
      line-height: 22px;
      /* font-family: 'Poppins-Medium'; */
      font-weight: 500;
}

.cust-acc-main .cust-acc-details {
      padding: 8px 16px 16px;
      background-color: #ffff;
      border: 1px solid #0000001f;
      border-top: 0px;
  }
.acc-details-main .acc-details-para {
      color: var(--main-secondary);
      line-height: 22px;
      font-size: 14px;
      /* font-family: 'Poppins-Regular'; */
}

.help-info-flex .help-info-lable {
      color: var(--main-primary);
      font-size: 17px;
      line-height: 22px;
      /* font-family: 'poppins-medium'; */
      font-weight: 500;
      margin-right: 5px;
}

.help-info-flex .help-info-data {
      color: var(--main-secondary);
      font-size: 14px;
      line-height: 22px;
      /* font-family: 'Poppins-Regular'; */
}

.help-info-flex {
      display: flex;
      align-items: center;
      width: 100%;
}

.help-info-flex:last-child {
      margin: 0px;
}

.help-info-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
  }
  