@media screen and (min-width: 1600px) and (max-width: 1920px) {}

@media screen and (min-width: 1366px) and (max-width: 1599px) {}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

@media screen and (min-width: 992px) and (max-width: 1279px) {}

@media screen and (max-width: 991px) {
    /* .user-sidebar-logo.mobile-show {display: block;} */
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    /* .body-overflow {
        overflow: hidden;
    } */
}

@media (max-width: 1025px) {
    .grid-main.stat-boxes .display-row {
        gap: 10px;
    }
    .grid-main.stat-boxes .user-dashboard-inner-box {
        padding: 15px 15px;
    }
}


@media (max-width: 767px) {
    .userlist-btn-flex .analytic-date-picker-main {
        margin-left: 0;
        max-width: calc(50% - 20px);
    }
    .userlist-btn-flex .mulitiple-datepicker .text {
        color: #000000;
        margin: 0 12px;
    }
    .userlist-btn-flex .date-picker-main .form-control {
        min-width: 100% !important;
    }
    .MuiDataGrid-menuIcon {
        display: none !important;
    }
    .user-list-flex {
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 0px;
    }

    .user-search-box {
        /* margin-right: 0px; */
        max-width: 100%;
        margin: auto;
        width: 100%;
        margin-bottom: 10px;
    }

    .user-search-flex {
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 0px;
        flex-direction: column;
    }

    .user-search-flex .user-page-sub-title {
        margin-bottom: 10px;
    }

    .user-page-title-box .user-page-title {
        font-size: 19px;
        line-height: 30px;
    }

    .primary-btn-content .comman-primary-btn,
    .primary-btn-content .comman-primary-btn:hover,
    .primary-btn-content .comman-primary-btn:active {
        font-size: 13px;
        line-height: 18px;
        padding: 10px 15px
    }

    .border-btn-main .border-btn,
    .border-btn-main .border-btn:active {
        font-size: 13px;
        line-height: 18px;
        padding: 10px 15px
    }
    .dashboard-containt-main {
        padding: 90px 15px 20px 15px;
    }
    .user-list-flex .user-page-title-box{
        margin-bottom: 10px;
    }
    .common-modal-inner .modal-title-text {
        font-size: 15px;
        line-height: 20px;
        padding-right: 28px;
    }
    .common-modal .common-modal-inner{
        min-width: 315px;
    }
}

@media screen and (min-width: 280px) and (max-width:374px) {}