@media (min-width: 767px) {
      .admin-project-report-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
      .admin-project-report-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
            width: 5% !important;
            min-width: 5% !important;
            max-width: 5% !important;
      }
      .admin-project-report-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
      .admin-project-report-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
            width: 17% !important;
            min-width: 17% !important;
            max-width: 17% !important;
      }
      .admin-project-report-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
      .admin-project-report-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
            width: 10% !important;
            min-width: 10% !important;
            max-width: 10% !important;
      }
      .admin-project-report-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
      .admin-project-report-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
            width: 10% !important;
            min-width: 10% !important;
            max-width: 10% !important;
      }
      .admin-project-report-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
      .admin-project-report-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
            width: 12% !important;
            min-width: 12% !important;
            max-width: 12% !important;
      }
      .admin-project-report-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
      .admin-project-report-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
            width: 10% !important;
            min-width: 10% !important;
            max-width: 10% !important;
      }
      .admin-project-report-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7),
      .admin-project-report-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
            width: 8% !important;
            min-width: 8% !important;
            max-width: 8% !important;
      }
      .admin-project-report-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(8),
      .admin-project-report-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(8) {
            width: 8% !important;
            min-width: 8% !important;
            max-width: 8% !important;
      }
      .admin-project-report-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(9),
      .admin-project-report-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(9) {
            width: 10% !important;
            min-width: 10% !important;
            max-width: 10% !important;
      }
      .admin-project-report-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(10),
      .admin-project-report-content .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(10) {
            width: 10% !important;
            min-width: 10% !important;
            max-width: 10% !important;
      }
      .admin-project-report-content .data-table-main .MuiDataGrid-root {min-width: 1200px;}
}

@media (max-width: 767px) {
      .admin-project-report-content .data-table-main .MuiDataGrid-root {min-width: 1010px;}
      .admin-project-report-content  div[data-field=id] {
            width: 5% !important;
            min-width: 5% !important;
            max-width: 5% !important;
      }
      .admin-project-report-content  div[data-field=email] {
            width: 15% !important;
            min-width: 15% !important;
            max-width: 15% !important;
      }
      .admin-project-report-content  div[data-field=title] {
            width: 10% !important;
            min-width: 10% !important;
            max-width: 10% !important;
      }
      .admin-project-report-content  div[data-field=tags] {
            width: 10% !important;
            min-width: 10% !important;
            max-width: 10% !important;
      }
      .admin-project-report-content  div[data-field=publishedate] {
            width: 10% !important;
            min-width: 10% !important;
            max-width: 10% !important;
      }
      .admin-project-report-content  div[data-field=isQRgenerated] {
            width: 10% !important;
            min-width: 10% !important;
            max-width: 10% !important;
      }
      .admin-project-report-content  div[data-field=serialNumber] {
            width: 10% !important;
            min-width: 10% !important;
            max-width: 10% !important;
      }
      .admin-project-report-content  div[data-field=QRCode] {
            width: 10% !important;
            min-width: 10% !important;
            max-width: 10% !important;
      }
      .admin-project-report-content  div[data-field=StartDate] {
            width: 10% !important;
            min-width: 10% !important;
            max-width: 10% !important;
      }
      .admin-project-report-content  div[data-field=EndDate] {
            width: 10% !important;
            min-width: 10% !important;
            max-width: 10% !important;
      }
}


.admin-project-report-content .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {white-space: wrap;}

