.pulished-iocn-main {
      display: flex;
      flex-wrap: wrap;
}

.pulished-iocn-main .published-icon-text {
      color: var(--main-primary);
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      /* font-family: 'Poppins-Regular'; */
      margin-left: 10px;
}

.published-icons {
      height: 25px;
      width: 25px;
      object-fit: contain;
}

.published-icoon-box {
      /* margin-right: 10px; */
      margin-right: 8px;
      margin-bottom: 10px;
      text-align: center;
      border: 1px solid var(--main-border);
      padding: 7px;
      border-radius: 6px;
      /* display: flex; */
      align-items: center;
      cursor: pointer;
}

.published-icoon-box:last-child {
      margin-right: 0px;
}

.upload-succsess-icon {
      /* height: 60px;
      width: 100%;
      object-fit: contain;
      max-width: 60px; */
      height: 118px;
      width: 100%;
      object-fit: contain;
}

.copy-icon {
      height: 20px;
      width: 20px;
      object-fit: contain;
      position: absolute;
      right: 11px;
      top: 11px;
      cursor: pointer;
}

.published-succses-main .published-para-text {
      color: #6B7280;
      font-size: 13px;
      line-height: 23px;
      /* font-family: 'Poppins-Regular'; */
      margin: 0px 0px 10px;
}

.copy-input-box {
      position: relative;
}

/* .qrscanner-img {
      height: 150px;
      max-width: 135px;
      width: 100%;
      object-fit: contain;
      border: 1px solid var(--main-border);
      margin-bottom: 10px;
} */
.qrscanner-img {
      height: 194px;
      max-width: 257px;
      width: 100%;
      object-fit: contain;
      border: 1px solid var(--main-border);
      /* margin-bottom: 10px; */
  }
.common-modal .publish-qr-modal-inner {
      min-width: 600px;
}
.published-qr-dropdown{
      max-width: 257px;
      margin-right: 5px;
      width: 100%;
      min-width: 150px;
      margin-bottom: 5px;
  }