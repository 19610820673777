.common-modal .save-as-draft-modal-inner {
      min-width: 400px;
}

.save-draft-modal-content .modal-title-text {
      text-align: center;
}

.modal-user-center-btn-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
}