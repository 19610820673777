
@media (min-width: 767px) {
      .user-payment-history-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
      .user-payment-history-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
            width: 5% !important;
            min-width: 5% !important;
            max-width: 5% !important;
      }
      .user-payment-history-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
      .user-payment-history-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
            width: 20% !important;
            min-width: 20% !important;
            max-width: 20% !important;
      }
      .user-payment-history-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
      .user-payment-history-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
            width: 20% !important;
            min-width: 20% !important;
            max-width: 20% !important;
      }
      .user-payment-history-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
      .user-payment-history-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
            width: 20% !important;
            min-width: 20% !important;
            max-width: 20% !important;
      }
      .user-payment-history-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
      .user-payment-history-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
            width: 17% !important;
            min-width: 17% !important;
            max-width: 17% !important;
      }
      .user-payment-history-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
      .user-payment-history-wrape .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
            width: 18% !important;
            min-width: 18% !important;
            max-width: 18% !important;
      }
}

@media (max-width: 767px) {
      .user-payment-history-wrape .data-table-main .MuiDataGrid-root {min-width: 767px;}
      .user-payment-history-wrape div[data-field=Sno] {
            width: 5% !important;
            min-width: 5% !important;
            max-width: 5% !important;
      }
      .user-payment-history-wrape div[data-field=paymentID] {
            width: 20% !important;
            min-width: 20% !important;
            max-width: 20% !important;
      }
      .user-payment-history-wrape div[data-field=mode] {
            width: 17% !important;
            min-width: 17% !important;
            max-width: 17% !important;
      }
      .user-payment-history-wrape div[data-field=amount] {
            width: 18% !important;
            min-width: 18% !important;
            max-width: 18% !important;
      }
      .user-payment-history-wrape div[data-field=date] {
            width: 20% !important;
            min-width: 20% !important;
            max-width: 20% !important;
      }
      .user-payment-history-wrape div[data-field=subscription_plan] {
            width: 20% !important;
            min-width: 20% !important;
            max-width: 20% !important;
      }
}


@media (max-width: 600px) {
      .user-payment-history-wrape .data-table-main .MuiDataGrid-root {min-width: 620px;}
}

.user-payment-history-wrape .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {white-space: wrap;}