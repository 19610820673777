.view-priview-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
}

.view-priview-flex .view-priview-text {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      /* font-family: 'Poppins-Regular'; */
}

.view-flex-col .view-priview-digit {
      color: var(--main-dark-blue);
      font-size: 17px;
      line-height: 25px;
      /* font-family: 'Poppins-SemiBold'; */
      font-weight: 700;
      margin-bottom: 20px;
      text-align: center;
}

.view-flex-col {
      border: 1px solid var(--main-border);
      padding: 10px;
      border-radius: 6px
}

.style-published-icons {
      height: 25px;
      width: 25px;
      object-fit: contain;
      margin-right: 20px;
}

.view-qr-dropdown {
      max-width: 193px;
      margin-right: 10px;
      width: 100%;
      min-width: 150px;
}
.view-qrscanner-img {
      height: 150px;
      max-width: 135px;
      width: 100%;
      object-fit: contain;
      border: 1px solid var(--main-border);
      margin-bottom: 10px;
}
.view-icoon-box {
      margin-right: 10px;
      margin-bottom: 10px;
      text-align: center;
      border: 1px solid var(--main-border);
      padding: 7px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;
}