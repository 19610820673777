.date-picker-main .form-control input{
    padding: 12px 10px 11px;
    font-size: 14px;
    line-height: 15px;
    /* font-family: 'poppins-regular'; */
    color: var(--main-primary);
    border-radius: 5px;
    box-sizing: border-box;
    height: auto;
    max-width: 100%;
    min-width: 100%;
}
.userlist-btn-flex .date-picker-main .form-control input{
    padding: 13px 10px 12px;
    font-size: 14px;
    line-height: 15px;
    /* font-family: 'poppins-regular'; */
    color: var(--main-primary);
    border-radius: 5px;
    box-sizing: border-box;
    height: auto;
    max-width: 100%;
    min-width: 100%;
}
.date-picker-main .form-control label{
    line-height: 15px;
}
.date-picker-main .form-control input::placeholder{
    color: var(--main-secondary);
}

.date-picker-main .MuiStack-root{
    padding: 0px;
    overflow: inherit;
}
.analytic-date-picker-main {
    margin-left: 10px;
    max-width: 210px;
}

.geo-location-map{
    width:100%;
    height: 500px;
    z-index: 1;
}
.flex-space{
display: flex;
justify-content: space-between;
}
.menu-report-section{
    margin-top: 20px;
}
.menu-report-section .grid-main .display-row {
    gap: 10px;
}
.menu-report-section .grid-main .display-row .chart-main-card.common-card {
    margin-bottom: 15px;
}

/* .chart-main-card .apexcharts-canvas, .chart-main-card .apexcharts-canvas svg {width: 100% !important; height: auto !important;} */