.input-box-auth .shap-text {
      color: #6B7280;
      font-size: 15px;
      line-height: 23px;
      /* font-family: 'Poppins-Regular'; */
}

.date-picker-main .form-control {
      width: 100%;
}

.date-picker-main .form-control .MuiButtonBase-root {
      position: absolute;
      right: 10px;
      z-index: 99;
}
.date-picker-main .form-control  .MuiInputBase-root{
      padding-right: 0px;
}