.project-dropdown{
      max-width: 300px;
      margin-left: 10px;
      width: 100%;
      /* min-width: 300px; */
}
.chart-main-card{
      padding: 15px;
      border-radius: 6px;
}

.graph-sections .grid-column {
      height: 100%;
}
.graph-sections .grid-column .chart-main-card.common-card {
      height: calc(100% - 55px);
}