.create-new-project-card {
      width: 100%;
}

.file-upload-plus-icon {
      height: 25px;
      width: 25px;
      object-fit: contain;
}

.file-upload-btn-main .file-upload-btn,
.file-upload-btn-main .file-upload-btn:hover {
      background-color: var(--main-transparent);
      padding: 10px;
      height: 60px;
      width: 100px;
      /* margin-top: 10px; */
}

.file-upload-btn-main .choose-img-text {
      color: #6B7280;
      font-size: 15px;
      line-height: 23px;
      /* font-family: 'Poppins-Regular'; */
      margin: 10px 0px 10px 20px;
}
.file-upload-box .media-upload-btn-main{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }
  .file-upload-box .media-upload-btn-main .media-upload-btn,
  .file-upload-box .media-upload-btn-main .media-upload-btn:hover {
        background-color: var(--main-transparent);
        padding: 10px;
        height: 40px; 
         width: 0px;
        /* margin-top: 10px; */
  }
  .file-upload-box .media-upload-btn-main .choose-img-text{
      margin: 5px 0px 0px 0px ;
  }
  

.file-upload-btn-main {
      display: flex;
      align-items: center;
}

/* .border-btn-main .cancel-user-btn {
      margin-right: 10px;
} */

.add-tags-inner-flex {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
}
.add-tags-flex {
      display: flex;
      flex-wrap: wrap;
  }
.add-tags-box {
      border: 1px solid var(--main-border);
      padding: 5px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
}
.add-tags-box:last-child{
      margin-right: 0px;
}
.add-tags-box .add-tags-text {
      color: #6B7280;
      font-size: 15px;
      line-height: 23px;
      /* font-family: 'Poppins-Regular'; */
}
.tags-addd-plus-main{
      background-color: var(--main-dark-blue);
      cursor: pointer;
}
.tag-upload-plus-icon{
      height: 18px;
      width: 18px;
      object-fit: contain;
}