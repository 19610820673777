.left-innersidebar {
  background: #fff;
  border-bottom: 1px solid #d1d5db;
  margin-bottom: 20px;
}

.right-innersidebar {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000014;
  border: 1px solid #d1d5db;
}

.profile-tab-design .MuiTabs-scroller {
  width: 100%;
  display: flex;
}

.profile-tab-design .MuiTabs-flexContainer {
  display: flex;
  flex-direction: row;
}

.profile-tab-design .MuiTabs-flexContainer button {
  min-width: max-content;
  min-height: unset;
  text-transform: capitalize;
  text-align: left;
  /* font-family: "poppins-regular"; */
  padding: 7.25px 20px;
  -webkit-align-items: flex-start;
  justify-content: flex-start !important;
  display: flex !important;
  line-height: 17px;
  color: #000;
}

.profile-tab-design .MuiTabs-scroller .MuiTabs-indicator {
  display: none;
}

.profile-tab-design .MuiTabs-flexContainer button.Mui-selected {
  color: var(--main-white);
  font-weight: 600;
  background: #2b324c;
}

.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-header {
  position: relative;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}
.profile-edit-main {
  position: absolute;
  right: 0px;
  bottom: 13px;
  cursor: pointer;
}
.profile-upload-logo-btn .MuiButton-startIcon {
  margin: 0px 10px 0px 0px;
}
.edit-div {
  background: #ededed;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d1d5db;
}

.profile-edit-img {
  height: 15px;
  width: 15px;
}
.profile-edit-main .edit {
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  opacity: 0;
}
.profile-upload-logo-btn {
  width: 100%;
  max-width: 170px;
  height: 100px;
}

.profile-edit-main .edit .MuiOutlinedInput-root {
  height: 30px;
}

.right-innersidebar .css-19kzrtu {
  padding: 0;
}

.upload-btn-main {
  height: 100%;
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.upload-btn-main .upload-profile-logo,
.upload-btn-main .upload-profile-logo:hover {
  width: 100%;
  background: transparent;
  padding: 0px;
  box-shadow: none;
  border: 1px solid var(--main-border);
  height: 100px;
}

.btn-blue {
  background: #2a324c;
  color: var(--main-white);
  text-transform: capitalize;
  /* font-family: "poppins-regular"; */
  font-size: 16px;
  line-height: 18px;
  padding: 9px 35px;
  border-radius: 5px;
  border: 0;
}

.btn-gray {
  background: var(--main-border);
  color: #2b334d;
  text-transform: capitalize;
  /* font-family: "poppins-regular"; */
  font-size: 16px;
  line-height: 18px;
  padding: 9px 35px;
  border-radius: 5px;
  border: 0;
}

.mt-30 {
  margin-top: 30px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.password-require-section {
  padding-left: 30px;
  padding-top: 5px;
}

.password-require-title {
  color: #2b334d;
  font-weight: 600;
  /* font-family: "poppins-regular"; */
}

.password-require-ensure {
  /* font-family: "poppins-regular"; */
  letter-spacing: 0px;
  font-size: 14px;
}

.password-ul {
  padding: 0;
  list-style: disc;
  padding-top: 20px;
}

.password-ul li {
  font-size: 14px;
  padding: 0;
  padding-bottom: 5px;
  /* font-family: "poppins-regular"; */
}
.profile-input-box {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 28px;
  display: flex;
  border: 1px solid var(--main-border);
}
.logo-img {
  height: 100px;
}
.company-upload-img-view {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.company-upload-img {
  object-fit: cover;
  width: 30px;
  height: 30px;
}
