.delete-btn-flex {
      display: flex;
      justify-content: center;
      align-items: center;
}

.common-modal .delete-modal-inner {
      min-width: 400px;
}

.delete-modal-content .modal-title-text {
      text-align: center;
      margin-top: 20px;
}
.common-modal .active-inactive-inner{
min-width: 500px;
}