

.table-section {
  background-color: #f0f3f8;
  border-radius: 6px;
  padding: 15px;
}

/* Add Edit */
.email-templete-create-new-project-card {
  max-width: 100%;
  width: 100%;
}
/* .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  height: 150px;
} */
.ck.ck-editor__main>.ck-editor__editable {
  height: 200px;
}
/* .email-template-table-main .MuiDataGrid-columnHeader, .email-template-table-main .MuiDataGrid-cell {width: 25% !important; max-width: 25% !important; min-width: 25% !important;} */


@media (min-width: 767px) { 
  .subscription-list .data-table-main .MuiDataGrid-root {min-width: 1010px;}

  .subscription-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
  .subscription-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
    width: 5% !important;
    min-width: 5% !important;
    max-width: 5% !important;
  }

  .subscription-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
  .subscription-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
    width: 28% !important;
    min-width: 28% !important;
    max-width: 28% !important;
  }

  .subscription-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
  .subscription-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
    width: 28% !important;
    min-width: 28% !important;
    max-width: 28% !important;
  }


  .subscription-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
  .subscription-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
    width: 29% !important;
    min-width: 29% !important;
    max-width: 29% !important;
  }

  .subscription-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
  .subscription-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
    width: 10% !important;
    min-width: 10% !important;
    max-width: 10% !important;
  }
}

@media (max-width: 767px) { 
  .subscription-list .data-table-main .MuiDataGrid-root {min-width: 767px;}

  .subscription-list div[data-field=id] {
    width: 5% !important;
    min-width: 5% !important;
    max-width: 5% !important;
  }
  .subscription-list div[data-field=title] {
    width: 20% !important;
    min-width: 20% !important;
    max-width: 20% !important;
  }
  .subscription-list div[data-field=subject] {
    width: 25% !important;
    min-width: 25% !important;
    max-width: 25% !important;
  }
  .subscription-list div[data-field=content] {
    width: 30% !important;
    min-width: 30% !important;
    max-width: 30% !important;
  }
  .subscription-list div[data-field=action] {
    width: 20% !important;
    min-width: 20% !important;
    max-width: 20% !important;
  }
}

@media (max-width: 600px) {
  .email-template-table-main .data-table-main .MuiDataGrid-root {
    min-width: 520px;
    width: 520px;
  }
}